<template>
  <QuestionWrapper
    :card="card"
  >

    <h3>
      Bitte teilen Sie uns Ihr Geschlecht sowie Ihr Geburts­jahr mit. 
    </h3>

    <div>
      <AppRadio
        v-model="gender"
        :value="2"
      >
        weiblich
      </AppRadio>

      <AppRadio
        v-model="gender"
        :value="1"
      >
        männlich
      </AppRadio>
    </div>

    <AppInput
      type="tel"
      v-model="birthyear"
      maxlength="4"
      placeholder="Geburtsjahr (4-stellig)"
    >
      Geburtsjahr
    </AppInput>

    <AppButton
      @click="submit"
      cls="material"
    >
      Speichern
    </AppButton>

  </QuestionWrapper>
</template>


<script>
import QuestionWrapper from '../QuestionWrapper'

export default {

  name: 'PersonDetails',

  components: {
    QuestionWrapper,
  },

  data(){
    return {
      gender: this.details.gender,
      birthyear: this.details.birthyear,
    }
  },

  props: {
    details: Object,
    card: Object,
  },

  computed: {
    scope(){
      return 'save-' + this.$options.name;
    }
  },

  methods: {

    submit(){

      if (!this.gender || !this.birthyear) {

        this.$swal({
          text: 'Bitte geben Sie sowohl Ihr Geschlecht als auch Ihr Geburtsjahr an.',
        });

        return false;
      }

      if (this.birthyear < 1900 || this.birthyear > new Date().getFullYear()) {

        this.$swal({
          text: 'Bitte geben Sie Ihr Geburtsjahr als vierstellige Jahreszahl an.',
        });

        return false;
      }

      this.appWorking(this.scope);
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'submit-personal-details',
        data: {
          gender: this.gender,
          birthyear: this.birthyear,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {
      
          this.appSuccess(
            'Ihre Angaben wurden erfolgreich gespeichert.', 
            this.scope
          );

          this.$emit(
            'cardSaved', 
            this.$options.name
          );
        }
      )
      .fail(
        resp => {
      
          this.appError(
            'Beim Speichern ist ein Fehler aufgetreten.', 
            null, 
            this.scope
          );
        }
      );

    },
  },
}
</script>


<style scoped>
.qColl .input {
  display: grid;
  grid-template-columns: minmax(20%, 144px) auto;
  grid-column-gap: 6px;
}
</style>