<template>
  <AppTable id="credits">
    
    <thead>
      <tr>
        <th
          @click="sortResults('creditsDisplay', 'date')"
          :class="sort.key === 'date' ? (sort.direction === 1 ? 'up' : 'down') : ''"
        >
          Datum
        </th>
        <th
          @click="sortResults('creditsDisplay', 'incentive')"
          :class="sort.key === 'incentive' ? (sort.direction === 1 ? 'up' : 'down') : ''"
        >
          Betrag
        </th>
        <th
          class="no-sort"
          colspan="3"
        >
          Studientitel/Anmerkung
        </th>
      </tr>
    </thead>

    <tbody>
      <!-- displayed credits -->
      <tr
        v-for="(c, i) in creditsDisplay"
        :key="'credit-' + i"
      >
        <td>
          {{ moment(c.date).format('DD.MM.YYYY') }}
        </td>
        <td>
          {{ c.incentive }}
        </td>
        <td colspan="3">
          {{ c.reason }}
        </td>
      </tr>

      <!-- searching for credits -->
      <tr
        v-if="scopedStatus['creditHistory'] === 1"
      >
        <td colspan="5">
          Gutschriften werden gesucht...
        </td>
      </tr>

      <!-- no credits -->
      <tr
        v-if="!credits.length && scopedStatus['creditHistory'] !== 1"
      >
        <td colspan="5">
          Es sind noch keine Gutschriften vorhanden
        </td>
      </tr>

      <!-- show more -->
      <tr
        v-if="credits.length > creditsDisplay.length"
      >
        <td colspan="5">
          <AppLink
            @click="showMore(true)"
            css="text-decoration: underline;"
          >
            mehr anzeigen
          </AppLink>
        </td>
      </tr>
    </tbody>

  </AppTable>
</template>


<script>
import sortTableMixin from '@/components/mixins/sortTable'

export default {

  name: 'AccountHistory',

  data(){
    return {
      credits: [],
      creditsDisplay: [],
    }
  },

  mixins: [sortTableMixin],

  mounted(){

    this.getMovements()
    
    this.sort.key = 'date';

    this.sort.direction = 2;
  },

  methods: {

    getMovements(){

      this.appWorking('creditHistory');
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'get-account-movements',
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {
      
          this.credits = readJson(resp);

          this.showMore();

          this.clearAppStatus('creditHistory');
        }
      )
      .fail(
        resp => {
      
          this.appError('Beim Abruf der Umsatz-Historie ist ein Fehler aufgetreten.');
        }
      );
    },

    showMore(showStatus){

      if (showStatus)
        this.appWorking('showMoreCredits')

      let newItems = this.credits.slice(
        this.creditsDisplay.length,
        this.creditsDisplay.length + 10
      );

      if (showStatus) {

        window.setTimeout(
          () => {

            this.clearAppStatus('showMoreCredits');

            this.creditsDisplay.push(...newItems);

            // if table-sorting was applied, sort it again (twice for restoring the desired sort-order)
            if (this.sort.key) {

              this.sortResults('creditsDisplay', this.sort.key)

              this.sortResults('creditsDisplay', this.sort.key)
            }
          },
          200
        );

      } else {

        this.creditsDisplay.push(...newItems);
      }
    },
  },
}
</script>


<style scoped>
#credits {
  margin: 12px 0;
}
#credits tr > td:nth-of-type(3) {
  text-align: left;
}
</style>