<template>
  <div>
  </div>
</template>


<script>
import navigationMixin from '@/components/mixins/navigationStore'

export default {

  name: 'FunctionalityAfterLogin',

  mixins: [navigationMixin],

  created(){

    this.bindSessionCheck();
  },

  beforeDestroy(){
    
    this.unbindSessionCheck();
  },

  methods: {

    bindSessionCheck(){

      this.checkSession = window.setInterval(
        () => {

          $.ajax({
            method: 'GET',
            url: this.urls.api + 'check-timeout',
            xhrFields: {
              withCredentials: true
            }
          })
          .fail(
            resp => {

              this.setLogin(false);

              if (this.$route.name !== 'portal')
                this.$router.push({name: 'portal'});

              this.appError('Sie waren 45 Minuten inaktiv und wurden daher automatisch aus dem System abgemeldet.');
            }
          );

        },
        3 * 1000 * 60
      );
    },

    unbindSessionCheck(){

      if (this.checkSession) {
        
        window.clearInterval(this.checkSession)
      }
    },

  },
}
</script>
