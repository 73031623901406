<template>
  <div id="changePassword">

    <h3>
      Passwort ändern
    </h3>

    <div>
      Um sicher zu gehen, dass Sie sich nicht versehentlich vertippen, geben Sie das gewünschte neue Passwort bitte zweimal ein. Die farbige Anzeige unterhalb des Feldes zeigt Ihnen an, wie sicher das eingegebene Passwort ist.
    </div>

    <div>
      Sofern Sie beim Anmelden die Einstellung "dauerhaft angemeldet bleiben" aktiviert haben, ist diese Einstellung nach der Änderung Ihres Passwortes zunächst unwirksam. Sie können diese jedoch selbstverständlich bei der nächsten Anmeldung erneut aktivieren.
    </div>

    <StatusMessage
      v-if="scopedStatus['change-password'] > 1"
      scope="change-password"
    />

    <AppInput
      type="password"
      v-model="oldPassword"
      placeholder="Ihr aktuelles Passwort"
      @enterPressed="submit"
    >
      Aktuelles Passwort
    </AppInput>

    <AppPasswordInput
      type="password"
      v-model="newPassword"
      placeholder="Ihr neues Wunsch-Passwort"
      @enterPressed="submit"
    >
      Neues Passwort
    </AppPasswordInput>

    <AppInput
      type="password"
      v-model="newPassword2"
      placeholder="Neues Wunsch-Passwort erneut eingeben"
      @enterPressed="submit"
    >
      Neues Passwort
    </AppInput>

    <AppButton
      @click="submit"
      cls="material"
    >
      Absenden
    </AppButton>
  </div>
</template>


<script>
export default {

  name: 'ChangePassword',

  data(){
    return {
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
    }
  },

  methods: {

    submit(){

      this.clearAppStatus();

      if (!this.oldPassword.length || !this.newPassword.length) {

        this.appError('Bitte füllen Sie sowohl das Eingabefeld für das bisherige Passwort als auch die Felder für das neue Passwort aus.', null, 'change-password');

        return false;
      }

      if (this.newPassword !== this.newPassword2) {

        this.appError('Das neue Passwort stimmt nicht in beiden Eingabefeldern überein.', null, 'change-password');

        return false;
      }

      if (this.newPassword.length < 5) {

        this.appError('Das neue Passwort muss mindestens aus 5 Zeichen bestehen.', null, 'change-password');

        return false;
      }

      this.appWorking('change-password');

      $.ajax({
        method: 'POST',
        url: this.urls.api + 'change-password',
        data: {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.appSuccess('Ihr Passwort für die Anmeldung in forsa.omninet wurde erfolgreich geändert.', 'change-password')

          this.oldPassword = '';

          this.newPassword = '';

          this.newPassword2 = '';
        }
      )
      .fail(
        resp => {

          let msg = resp.status === 406 ? 'Das eingegebene bisherige Passwort ist falsch!' : 'Beim Ändern des Passworts ist ein Fehler aufgetreten.';

          this.appError(msg, null, 'change-password');
        }
      );
    },
  },
}
</script>


<style scoped>
#changePassword > * {
  margin: 0 0 24px 0;
  max-width: 800px;
}
#changePassword > h3 {
  margin-top: 48px;
}
#changePassword > .input {
  display: grid;
  grid-template-columns: minmax(20%, 144px) auto;
  grid-column-gap: 6px;
}
#changePassword > .button {
  text-align: center;
}
@media only screen and (max-width: 640px) {
  #changePassword > .input {
    display: block;
  }
}
</style>