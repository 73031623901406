<template>
  <div id="contact">

    <h3>
       Haben Sie Fragen?
    </h3>

    <div>
      Bei allen Fragen oder Problemen, aber selbstverständlich auch für alle Wünsche und Anregungen steht Ihnen das <b>forsa.omninet Support-Team</b> montags bis freitags von 9.00 bis 18.00 Uhr zur Verfügung.
    </div>
    
    <h3>
       Rufen Sie uns an
    </h3>

    <div>
      <div>
        Telefonnummer: 0800 367 72 01
      </div>
      <div class="smaller">
        (Montag bis Freitag 9.00 bis 18.00 Uhr) 
      </div>
    </div>
    
    <h3>
       Schreiben Sie uns
    </h3>

    <StatusMessage
      scope="send-message"
      v-if="scopedStatus['send-message'] > 1"
    />

    <div>
      <AppTextarea
        v-model="mail"
        css="min-height: 288px; resize: vertical;"
        placeholder="Ihre Nachricht an das forsa.omninet Support-Team"
        class="noTitle"
      />
      <AppButton
        @click="submitMail"
        cls="material"
      >
        Absenden
      </AppButton>
    </div>

  </div>
</template>


<script>
export default {

  name: 'Contact',

  data(){
    return {
      mail: '',
    }
  },

  methods: {

    submitMail(){

      this.clearAppStatus();

      if (!this.mail.length) {
      
        this.appError(
          'Bitte geben Sie Ihre Nachricht an das forsa.omninet Support-Team in das Eingabefeld ein.', 
          null, 
          'send-message'
        );
        
        return false;
      }

      this.appWorking('send-message');

      $.ajax({
        method: 'POST',
        url: this.urls.api + 'send-support-mail',
        data: {
          msg: this.mail,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.appSuccess(
            'Ihre Nachricht an das forsa.omninet Support-Team wurde erfolgreich versendet. Wir werden uns in Kürze wieder bei Ihnen melden.', 
            'send-message'
          );

          this.mail = '';
        }
      )
      .fail(
        resp => {

          this.appError(
            'Beim Versand Ihrer Nachricht ist ein Fehler aufgetreten.', 
            null, 
            'send-message'
          );
        }
      );
    },
  },
}
</script>


<style scoped>
#contact > * {
  margin: 0 0 24px 0;
  hyphens: auto;
  max-width: 800px;
}
#contact > h3 {
  margin-top: 48px;
}
#contact .smaller {
  margin: 12px 0;
  font-size: 90%;
  font-style: italic;
}
#contact .textarea {
  font-size: 90%;
  margin: 12px 0 24px 0;
}
#contact .button {
  text-align: center;
}
</style>