<template>
  <div id="portal">
    
    <HorizontalContainer>

      <LoginForm
        v-if="!loggedIn"
      />

      <PortalContent
        v-else
      />

      <FullscreenSpinner />

    </HorizontalContainer>

    <Footer />

  </div>
</template>


<script>
import navigationMixin from '@/components/mixins/navigationStore'

import HorizontalContainer from '@/components/wrapper/HorizontalContainer'

import LoginForm from '@/components/sections/TheLoginForm'
import PortalContent from '@/components/sections/ThePortalContent'
import Footer from '@/components/sections/TheFooter'

export default {

  name: 'ViewPortal',

  components: {
    HorizontalContainer,
    LoginForm,
    PortalContent,
    Footer,
  },

  mixins: [navigationMixin],
}
</script>


<style scoped>
#portal {
  display: flex;
  flex-direction: column;
}
#portal > .hContainer {
  flex-grow: 1;
  padding: 48px 12px 24px 12px;
}
</style>