import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import Portal from '@/views/Portal.vue'

Vue.use(VueRouter)

const router = new VueRouter({

  mode: 'history',

  scrollBehavior(to, from, savedPosition){

    if (savedPosition) 
      return savedPosition;

    let w = document.getElementById('window');

    if (w !== null)
      w.scrollTop = 0;

    return {
      x: 0,
      y: 0 
    };
  },

  base: process.env.BASE_URL,

  routes: [
    {
      path: '/',
      name: 'portal',
      component: Portal,
    },
    // {
    //   path: '/portal/overview',
    //   name: 'portal-overview',
    //   component: Portal,
    // },
    {
      path: '/portal/surveys',
      name: 'portal-surveys',
      component: Portal,
    },
    {
      path: '/portal/account',
      name: 'portal-account',
      component: Portal,
    },
    {
      path: '/portal/profile',
      name: 'portal-profile',
      component: Portal,
    },
    // {
    //   path: '/portal/profile/politics',
    //   name: 'portal-profile-politics',
    //   component: Portal,
    // },
    // {
    //   path: '/portal/profile/education',
    //   name: 'portal-profile-education',
    //   component: Portal,
    // },
    // {
    //   path: '/portal/profile/household',
    //   name: 'portal-profile-household',
    //   component: Portal,
    // },
    {
      path: '/portal/help',
      name: 'portal-help',
      component: Portal,
    },
    // {
    //   path: '/portal/help/faq',
    //   name: 'portal-help-faq',
    //   component: Portal,
    // },
    // {
    //   path: '/portal/help/about',
    //   name: 'portal-help-about',
    //   component: Portal,
    // },
    {
      path: '/portal/help/quit',
      name: 'portal-quit',
      component: Portal,
    },
    // {
    //   path: '/portal/change-password',
    //   name: 'portal-change-password',
    //   component: Portal,
    // },
    {
      path: '/imprint',
      name: 'imprint',
      component: () => import('@/views/Imprint.vue'),
    },
    {
      path: '/data-protection',
      name: 'data-protection',
      component: () => import('@/views/DataProtection.vue'),
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('@/views/Signup.vue'),
    },
    {
      path: '/signup/:code',
      name: 'signup-with-code',
      component: () => import('@/views/Signup.vue'),
    },
    {
      path: '/referral',
      name: 'referral-signup',
      component: () => import('@/views/Signup.vue'),
      props: {
        mode: 'referral',
      },
    },
    {
      path: '/referral/:code',
      name: 'referral-signup-with-code',
      component: () => import('@/views/Signup.vue'),
      props: {
        mode: 'referral',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/ForgotPassword.vue'),
    },
    // {
    //   path: '/restore-password',
    //   name: 'restore-password',
    //   component: () => import('@/views/RestorePassword.vue'),
    // },
    {
      path: '/restore-password/:code',
      name: 'restore-password',
      component: () => import('@/views/RestorePassword.vue'),
    },
    {
      path: '/activate-email/:code',
      name: 'activate-email',
      component: () => import('@/views/ActivateEmail.vue'),
    },
    {
      path: '/logout',
      name: 'logout',
    },
    {
      path: '/*',
      name: 'not-found',
      component: Portal,
    },
  ],
})

router.beforeEach(
  (to, from, next) => {

    console.log('redirect to', to.name)

    // if logout is requested, call to server and return to login (no authorization needed)
    if (to.name === 'logout') {

      $.ajax({
        method: 'POST',
        url: store.getters.urls.api + 'logout',
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          let arg = {
            scope: null,
            status: 3,
            message: 'Sie wurden aus dem System abgemeldet.',
            detail: '',
          }

          store.dispatch('setAppStatus', arg);

          store.dispatch('setLoginState', false);

          next({name: 'portal'});
        }
      );
    }

    // map urls to portal-sections
    switch (to.name) {

      case 'portal':

        store.dispatch('setSection', 'surveys');

        break;

      case 'imprint':
      case 'data-protection':

        store.dispatch('setSection', to.name);

        break;

      case 'not-found':

        next({name: 'portal-surveys'});

        break;

      default:

        if (to.name.indexOf('portal-') === 0) {

          let sect = to.name.substr(7);

          store.dispatch('setSection', sect);

          store.dispatch('clearGlobalAppStatus');
        }
    }

    // if any portal-site was requested, check server for valid PHP-session
    if (to.name.indexOf('portal') === 0) {

      store.dispatch('clearScopes');

      store.dispatch('setScopeWorking', 'check-login');

      $.ajax({
        method: 'POST',
        url: store.getters.urls.api + 'check-login',
        xhrFields: {
          withCredentials: true
        }
      })
      // if active session is running and access is granted
      .done(
        resp => {

          store.dispatch('deleteScope', 'check-login');

          store.dispatch('setLoginState', true);
        }
      )
      // if server responds with an error
      .fail(
        resp => {

          store.dispatch('deleteScope', 'check-login');

          store.dispatch('setLoginState', false);

          if (to.name === 'portal')
            return;

          let arg = {
            scope: null,
            status: 2,
            message: 'Ein Fehler ist aufgetreten.',
            detail: '',
          }

          // show error-message according to HTTP-code
          switch (resp.status) {

            case 0:
              console.log('Oopsy! Something went wrong. Your call was blocked.');
              break;

            case 400:
              console.log('Your request is invalid!');
              break;

            case 401:
              arg.message = 'Um diesen Bereich zu betreten, müssen Sie sich mit Ihren Zugangsdaten in forsa.omninet anmelden.';
              store.dispatch('setAppStatus', arg);
              break;

            case 412:
              arg.message = 'Sie waren 45 Minuten inaktiv und wurden daher automatisch aus dem System abgemeldet.';
              store.dispatch('setAppStatus', arg);
              break;

            case 404:
              console.log('The backend is lost, please find it first!');
              break;

            default:
              console.log('Oopsy! Something went wrong.');
              break;
          }
        }
      );
    }

    next();
  }
)

export default router
