<template>
  <QuestionWrapper
    :card="card"
  >
    <div>
      <h3>
        Wie ist Ihr Familienstand?
      </h3>

      <div>
        <AppRadio
          v-for="(c, i) in catsMs"
          :key="'categoryMs-' + i"
          v-model="maritalStatus"
          :value="c.val"
          :class="i === catsMs.length - 1 ? 'dk' : ''"
        >
          {{ c.label }}
        </AppRadio>
      </div>
    </div>

    <transition name="fade">
      <div
        v-if="maritalStatus > 1"
      >

        <h3>
          Leben Sie in Ihrem Haushalt mit einem Partner zusammen?
        </h3>

        <div>
          <AppRadio
            v-for="(c, i) in catsCo"
            :key="'categoryCo-' + i"
            v-model="cohabitation"
            :value="c.val"
            :class="i === catsCo.length - 1 ? 'dk' : ''"
          >
            {{ c.label }}
          </AppRadio>
        </div>

      </div>
    </transition>


    <AppButton
      @click="submit"
      cls="material"
    >
      Speichern
    </AppButton>

  </QuestionWrapper>
</template>


<script>
import QuestionWrapper from '../QuestionWrapper'

export default {

  name: 'MaritalStatus',

  components: {
    QuestionWrapper,
  },

  data(){
    return {
      maritalStatus: this.details.marital_status,
      catsMs: [
        {val: 1, label: 'verheiratet, im gemeinsamen Haushalt'},
        {val: 2, label: 'verheiratet, getrennt lebend'},
        {val: 3, label: 'ledig'},
        {val: 4, label: 'geschieden'},
        {val: 5, label: 'verwitwet'},
        {val: 6, label: 'keine Angabe'},
      ],
      cohabitation: this.details.cohabitation,
      catsCo: [
        {val: 1, label: 'ja'},
        {val: 2, label: 'nein'},
        {val: 3, label: 'keine Angabe'},
      ],
    }
  },

  props: {
    details: Object,
    card: Object,
  },

  computed: {
    scope(){
      return 'save-' + this.$options.name;
    }
  },

  watch: {
    
    maritalStatus(newVal, oldVal){
      
      if (newVal === 1)
        this.cohabitation = 1;

      if (oldVal === 1)
        this.cohabitation = null;
    },
  },

  methods: {

    submit(){

      if (!this.maritalStatus || !this.cohabitation) {

        this.$swal({
          text: 'Bitte wählen Sie eine Antwortkategorie aus.',
        });

        return false;
      }

      this.appWorking(this.scope);
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'submit-marital-status',
        data: {
          maritalStatus: this.maritalStatus,
          cohabitation: this.cohabitation,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {
      
          this.appSuccess('Ihre Antwort wurde erfolgreich gespeichert.', this.scope);

          this.$emit('cardSaved', this.$options.name);
        }
      )
      .fail(
        resp => {
      
          this.appError('Beim Speichern Ihrer Antwort ist ein Fehler aufgetreten.', null, this.scope);
        }
      );
    },
  },
}
</script>


<style scoped>
.qColl .qCont > *:not(.button) {
  padding: 0;
}
.qColl .qCont > div > * {
  padding: 12px;
}
</style>