<template>
  <QuestionWrapper
    :card="card"
  >

    <h3>
      Sind Sie selbst oder jemand anderes in Ihrem Haushalt Mitglied einer Gewerkschaft?
    </h3>

    <div>
      <AppRadio
        v-for="(c, i) in cats"
        :key="'category-' + i"
        v-model="response"
        :value="c.val"
        :class="i === cats.length - 1 ? 'dk' : ''"
      >
        {{ c.label }}
      </AppRadio>
    </div>

    <AppButton
      @click="submit"
      cls="material"
    >
      Speichern
    </AppButton>

  </QuestionWrapper>
</template>


<script>
import QuestionWrapper from '../QuestionWrapper'

export default {

  name: 'LabourUnion',

  components: {
    QuestionWrapper,
  },

  data(){
    return {
      response: this.details.labour_union,
      cats: [
        {val: 1, label: 'ja'},
        {val: 2, label: 'nein'},
        {val: 3, label: 'keine Angabe'},
      ],
    }
  },

  props: {
    details: Object,
    card: Object,
  },

  computed: {
    scope(){
      return 'save-' + this.$options.name;
    }
  },

  methods: {

    submit(){

      if (!this.response) {

        this.$swal({
          text: 'Bitte wählen Sie eine Antwortkategorie aus.',
        });

        return false;
      }

      this.appWorking(this.scope);
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'submit-labour-union',
        data: {
          response: this.response,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {
      
          this.appSuccess('Ihre Antwort wurde erfolgreich gespeichert.', this.scope);

          this.$emit('cardSaved', this.$options.name);
        }
      )
      .fail(
        resp => {
      
          this.appError('Beim Speichern Ihrer Antwort ist ein Fehler aufgetreten.', null, this.scope);
        }
      );
    },
  },
}
</script>
