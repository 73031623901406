<template>
  <div class="chart">

    <div class="headline">
      {{ config.headline || 'Eine forsa-Umfrage hat ergeben&hellip;' }}
    </div>

    <div class="intro">
      {{ config.intro }}
    </div>

    <canvas
      :id="'chartContainer-' + $vnode.key"
      width="200"
      height="150"
    >
    </canvas>

    <div class="footer">
      {{ config.footer }}
    </div>

    <div class="source">
      {{ config.source }}
    </div>
  </div>
</template>


<script>
import Chart from 'chart.js'

export default {

  name: 'Chart',

  props: {
    config: Object,
    opacity: {
      type: Number,
      default: 1,
    },
  },

  computed: {

    chartCfg(){

      let c = this.config;

      let cfg = {
        type: c.type,
        data: {
          labels: JSON.parse('[' + c.label_array.split(',') + ']'),
          datasets: [{
            data: JSON.parse('[' + c.value_array.split(',') + ']'),
            backgroundColor: JSON.parse('[' + c.color_array.split(',') + ']').map(v => 'rgba(' + this.hexToRgb(v).join(',') + ',' + this.opacity + ')'),
            borderWidth: 2,
          }]
        },
        options: {
          animation: {
            animateRotate: true,
            animateScale: false,
            duration: 1600,
            easing: 'easeOutBounce',
          },
          tooltips: {
            callbacks: {
              title: function(item, data) {
                return false;
              },
              label: function(item, data) {
                var allData = data.datasets[item.datasetIndex].data;
                var tooltipLabel = Array.isArray(data.labels[item.index]) ? data.labels[item.index].join(" ") : data.labels[item.index];
                var tooltipData = allData[item.index];
                return tooltipLabel + ": " + tooltipData + "%";
              },
            },
            cornerRadius: 0,
          },
          legend: {
            display: true,
            position: c.legend_position || 'top',
            onClick: null,
            labels: {
              fontFamily: '"Nunito Sans", sans-serif',
            }
          },
          cutoutPercentage: 75,
          rotation: 0.5 * Math.PI,
        },
      }

      if (cfg.type === 'bar' || cfg.type === 'horizontalBar') {

        cfg.options.legend.display = false;

        cfg.options.scales = {
          xAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: 10,
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: 10,
            }
          }],
        }
      }

      return cfg;
    },
  },

  mounted(){

    this.createChart();
  },

  beforeDestroy(){

    if (this.chart)
      this.chart.destroy();
  },

  methods: {

    createChart(){

      const ctx = document.getElementById('chartContainer-' + this.$vnode.key);

      this.chart = new Chart(ctx, this.chartCfg);
    },

    hexToRgb(hex){ //https://stackoverflow.com/a/39077686

      return hex.replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => '#' + r + r + g + g + b + b
      )
      .substring(1).match(/.{2}/g)
      .map(x => parseInt(x, 16))
    },
  },
}
</script>


<style scoped>
.chart {
  position: relative;
  min-width: 0;
  padding: 12px 12px 18px 12px;
  max-width: 600px;
  margin: 0 auto;
}
.chart > div:not(:last-of-type) {
  margin-bottom: 12px;
}
.headline {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: bold;
}
.footer,
.source {
  font-size: 80%;
  font-style: italic;
}
</style>