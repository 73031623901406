export default {

  data(){
    return {
      open: [],
      closed: [],
    }
  },

  methods: {

    getSurveys(){

      this.appWorking('get-surveys');
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'get-member-surveys',
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.clearAppStatus('get-surveys');
      
          let surveys = readJson(resp);

          this.open = surveys//.unfinished;

          this.$store.dispatch('setSurveyCount', this.open.length)

          //this.closed = surveys.finished;
        }
      )
      .fail(
        resp => {
      
          this.appError('Beim Abruf der Studien ist ein Fehler aufgetreten.');
        }
      );
    },

  },
}

