<template>
  <div id="surveys">
    
    <h1>
      Umfragen
    </h1>

    <h3>
      Verfügbare Umfragen
    </h3>

    <div
      v-if="open.length"
      class="surveyList"
    >
      <Survey
        v-for="(s, i) in open"
        :key="'openSurvey-' + i"
        :params="s"
        status="open"
      />
    </div>

    <div
      v-else
    >
      <div
        v-if="scopedStatus['get-surveys'] === 1"
      >
        Verfügbare Umfragen werden gesucht...
      </div>
      <div
        v-else
      >
        Derzeit stehen keine offenen Umfragen zur Verfügung.
      </div>
    </div>

    <h3>
      Aktuelle Profilfragen
    </h3>

    <StatusMessage
      scope="get-cards"
      v-if="scopedStatus['get-cards'] === 3"
    />

    <div
      v-if="tabLabels.length"
      class="profile"
    >
      <AppTabs
        v-model="show"
        :labels="tabLabels"
        :class="'bordered ' + (isFading ? 'fadeOutTab' : '')"
        @change="clearAppStatus()"
      >
        <component
          v-if="show"
          :is="selectedCard.vue_component_name"
          :details="details"
          :card="selectedCard"
          @cardSaved="initiateTabFadeOut"
        />
      </AppTabs>
    </div>
    <div
      v-else
    >
      Perfekt, Sie haben alle Profilfragen beantwortet!
    </div>

    <Charts />

    <!-- <h3>
      Vergangene Umfragen
    </h3>

    <div
      v-if="closed.length"
      class="grid"
    >
      <Survey
        v-for="(s, i) in closed"
        :key="'closedSurvey-' + i"
        :params="s"
        status="closed"
      />
    </div>

    <div
      v-else
    >
      <div
        v-if="scopedStatus['get-surveys'] === 1"
      >
        Vergangene Umfragen werden gesucht...
      </div>
      <div
        v-else
      >
        Sie haben noch an keinen Umfragen teilgenommen.
      </div>
    </div> -->

  </div>
</template>


<script>
import getSurveyMixin from '@/components/mixins/getSurveys'

import Charts from './TheCharts'

import Survey from './survey/Survey'

import Salutation from './profile/address/Salutation'
import Contact from './profile/address/Contact'
import PersonDetails from './profile/address/PersonDetails'
import Residence from './profile/address/Residence'
import Income from './profile/education/Income'
import LabourUnion from './profile/education/LabourUnion'
import Employment from './profile/education/Employment'
import MaritalStatus from './profile/living/MaritalStatus'
import Household from './profile/living/Household'
import ElectionDecision from './profile/politics/ElectionDecision'
import ElectionIntention from './profile/politics/ElectionIntention'
import Religion from './profile/politics/Religion'

export default {

  name: 'Surveys',

  components: {
    Charts,
    Survey,
    Salutation,
    Contact,
    PersonDetails,
    Residence,
    Income,
    LabourUnion,
    Employment,
    MaritalStatus,
    Household,
    ElectionDecision,
    ElectionIntention,
    Religion,
  },

  data(){
    return {
      cards: [],
      details: {},
      show: null,
      isFading: false,
    }
  },

  computed: {

    tabLabels(){

      return this.cards.map(
        c => {
          return {
            val: c.vue_component_name,
            label: c.card_name,
          };
        }
      );
    },

    selectedCard(){

      return !this.show ? {} : this.cards.find(c => c.vue_component_name === this.show);
    },
  },

  mixins: [getSurveyMixin],

  mounted(){

    this.getSurveys(true);

    this.getOpenProfileCards();
  },

  methods: {

    getOpenProfileCards(){

      this.appWorking('get-cards');
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'get-unanswered-profile-cards',
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.clearAppStatus('get-cards');
      
          let unwrap = readJson(resp);

          this.cards = unwrap.cards.filter(c => !c.has_value || c.needs_refresh);

          this.$store.dispatch('setProfileCount', this.cards.length)

          this.details = unwrap.details;

          this.showFirstCard();
        }
      )
      .fail(
        resp => {
      
          this.appError('Ein Fehler ist aufgetreten.', null, 'get-cards');
        }
      );
    },

    showFirstCard(){

      if (this.cards.length)
        this.show = this.cards[0].vue_component_name;
      else
        this.show = null;
    },

    initiateTabFadeOut(name){

      let count = this.$store.getters.getCurrentProfileCount;

      this.$store.dispatch('setProfileCount', count > 0 ? count - 1 : 0);

      window.setTimeout(
        () => {
          this.startFadeOut(name)
        },
        1600
      );
    },

    startFadeOut(name){

      this.isFading = true;

      window.setTimeout(
        () => {
          this.removeTab(name)
        },
        1200
      );
    },

    removeTab(name){

      let idx = this.cards.map(c => c.vue_component_name).indexOf(name);

      this.cards.splice(idx, 1);

      this.showFirstCard();

      this.isFading = false;
    },

  },
}
</script>


<style>
#surveys .tabs > .header .formControlHolder.selected,
#surveys .tabs > .header .formControlHolder.selected::after {
  background: #d8d8d5;
}
</style>


<style scoped>
#surveys > * {
  margin: 0 0 24px 0;
  max-width: 800px;
}
#surveys > h1 {
  margin-bottom: 48px;
}
#surveys > h3 {
  margin-top: 48px;
}
#surveys .profile .tabs >>> .content > * {
  padding: 0;
}
#surveys .profile .fadeOutTab >>> .qColl {
  opacity: 0;
  transition: opacity 1.2s ease-in;
}
#surveys .surveyList > * {
  margin-bottom: 24px;
}
</style>