<template>
  <div id="navigation">
    
    <div
      class="mobile"
      v-click-outside="onClickOutside"
    >
      <div>
        <AppLink
          css="display: block;"
          @click="show = true"
          class="toggleMenu"
        >
          <i class="fas fa-bars"></i>
        </AppLink>
      </div>

      <transition name="fadeOut">
        <NavigationLinks
          v-if="show"
          :noSubmenuAutoRouting="true"
          @hideMenu="show = false"
        />
      </transition>
    </div>

    <div class="desktop">
      <NavigationLinks />
    </div>

  </div>
</template>


<script>
import vClickOutside from 'v-click-outside'

import navigationMixin from '@/components/mixins/navigationStore'

import NavigationLinks from './TheNavigationLinks'

export default {

  name: 'Navigation',

  components: {
    NavigationLinks,
  },

  data(){
    return {
      show: false,
    }
  },

  mixins: [navigationMixin],

  directives: {
    clickOutside: vClickOutside.directive,
  },

  methods: {

    onClickOutside(e){

      if (this.show)
        this.show = false;
    },
  },
}
</script>


<style>
.toggleMenu {
  margin-left: 12px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  box-shadow: none;
  display: inline-block;
  line-height: 56px;
}
@media only screen and (min-width: 640px) {
  #navigation .desktop > .menuLinks > .subMenu {
    display: none;
  }
}
</style>


<style scoped>
#navigation .mobile {
  width: 100%;
  max-width: 300px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 950;
}
.fadeOut-leave-active {
  transition: opacity .4s;
}
.fadeOut-leave-to {
  opacity: 0;
}
#navigation .desktop {
  display: none;
}
@media only screen and (min-width: 640px) {
  #navigation .mobile {
    display: none;
  }
  #navigation .desktop {
    display: block;
  }
}
</style>