<template>
  <div id="footer">

    <HorizontalContainer>
      <a
        href="https://www.forsa.de"
        target="_blank"
      >
        <img src="@/assets/logo_forsa.png" />
      </a>

      <div>
        <router-link
          to="/imprint"
        >
          <span tabindex="-1">
            Impressum
          </span>
        </router-link>

        <router-link
          to="/data-protection"
        >
          <span tabindex="-1">
            Datenschutz
          </span>
        </router-link>
      </div>
    </HorizontalContainer>
    
  </div>
</template>


<script>
import HorizontalContainer from '@/components/wrapper/HorizontalContainer'

export default {

  name: 'Footer',

  components: {
    HorizontalContainer,
  },
}
</script>


<style scoped>
#footer {
  background: #d8d8d5;
  padding-bottom: 12px;
}
.hContainer {
  display: flex;
  column-gap: 12px;
  align-items: flex-end;
}
.hContainer > div {
  flex-grow: 1;
  display: flex;
  column-gap: 12px;
  justify-content: space-around;
}
#footer img {
  max-height: 25px;
  vertical-align: bottom;
  margin: 12px 12px 0 12px;
}
@media only screen and (max-width: 360px) {
  #footer img {
    display: none;
  }
  #footer .hContainer > * {
    margin-top: 12px;
  }
}
</style>