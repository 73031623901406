<template>
  <QuestionWrapper
    :card="card"
  >
    
    <h3>
      Welche Partei würden Sie wählen, wenn am <u>nächsten</u> Sonntag Bundestagswahl wäre?
    </h3>

    <div>
      <AppRadio
        v-for="(c, i) in cats"
        :key="'category-' + i"
        v-model="response"
        :value="c.val"
        :class="i === cats.length - 1 ? 'dk' : ''"
      >
        {{ c.label }}
      </AppRadio>
    </div>

    <AppButton
      @click="submit"
      cls="material"
    >
      Speichern
    </AppButton>

  </QuestionWrapper>
</template>


<script>
import QuestionWrapper from '../QuestionWrapper'

export default {

  name: 'ElectionIntention',

  components: {
    QuestionWrapper,
  },

  data(){
    return {
      response: this.details.election_intention,
      cats: [
        {val: 3, label: 'SPD'},
        {val: 1, label: 'CDU'},
        {val: 2, label: 'CSU'},
        {val: 5, label: 'Bündnis 90 / Die Grünen'},
        {val: 4, label: 'FDP'},
        {val: 8, label: 'AfD / Alternative für Deutschland'},
        {val: 6, label: 'Die Linke'},
        {val: 16, label: 'Freie Wähler'},
        {val: 7, label: 'Piratenpartei'},
        {val: 11, label: 'Die Heimat (ehem. NPD)'},
        {val: 17, label: 'BSW (Bündnis Sahra Wagenknecht)'},
        {val: 12, label: 'eine andere Partei'},
        {val: 14, label: 'ich gehe nicht wählen'},
        {val: 13, label: 'ich bin nicht wahlberechtigt'},
        {val: 15, label: 'weiß nicht / keine Angabe'}
      ],
    }
  },

  props: {
    details: Object,
    card: Object,
  },

  computed: {
    scope(){
      return 'save-' + this.$options.name;
    }
  },

  methods: {

    submit(){

      if (!this.response) {

        this.$swal({
          text: 'Bitte wählen Sie eine Antwortkategorie aus.',
        });

        return false;
      }

      this.appWorking(this.scope);
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'submit-election-intention',
        data: {
          response: this.response,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {
      
          this.appSuccess('Ihre Antwort wurde erfolgreich gespeichert.', this.scope);

          this.$emit('cardSaved', this.$options.name);
        }
      )
      .fail(
        resp => {
      
          this.appError('Beim Speichern Ihrer Antwort ist ein Fehler aufgetreten.', null, this.scope);
        }
      );
    },
  },
}
</script>
