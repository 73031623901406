<template>
  <div id="login">

    <h1>
      Herzlich willkommen bei forsa.omninet
    </h1>

    <StatusMessage
      v-if="appStatus !== 1"
    />

    <div class="grid">

      <div class="grid2">
        <AppInput
          v-model="username"
          placeholder="Benutzername / E-Mail-Adresse"
          css="padding: 6px;"
          class="username"
          @enterPressed="login"
        >
          <i class="fas fa-user-circle fa-fw"></i>
          Benutzername&nbsp;/ E-Mail-Adresse
        </AppInput>

        <AppInput
          v-model="password"
          type="password"
          placeholder="Passwort"
          css="padding: 6px;"
          @enterPressed="login"
        >
          <i class="fas fa-key fa-fw"></i>
          Passwort
        </AppInput>

        <AppCheckbox
          v-model="cookie"
          style="grid-column-start: 2;"
        >
          dauerhaft angemeldet bleiben
        </AppCheckbox>
      </div>

      <div>
        <AppButton
          @click="login"
          cls="material"
          css="min-width: 144px;"
        >
          <StatusIcon
            v-if="appStatus === 1"
          />
          <span
            v-else
          >
            Anmelden
          </span>
        </AppButton>
      </div>

    </div>

    <AppLink
      @click="redirectToForgotPassword"
      class="forgotPw"
    >
      Ich habe mein Passwort vergessen
    </AppLink>
<!--
    <div class="forgotPw">
      <a
        href="/signup"
      >
        Ich habe einen Registrierungs-Code erhalten
      </a>
    </div>
-->
    <Charts
      page="login"
      class="chartContainer"
    />

  </div>
</template>


<script>
import navigationMixin from '@/components/mixins/navigationStore'

import Charts from './portal/TheCharts'

export default {

  name: 'LoginForm',

  components: {
    Charts,
  },

  data(){
    return {
      username: null,
      password: null,
      cookie: false,
    }
  },

  mixins: [navigationMixin],

  methods: {

    login(){

      this.appWorking();

      $.ajax({
        method: 'POST',
        url: this.urls.api + 'login',
        data: {
          username: this.username,
          password: this.password,
          cookie: this.cookie,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.setLogin(true);

          this.clearAppStatus();

          this.appSuccess('Sie haben sich erfolgreich in forsa.omninet angemeldet.');
        }
      )
      .fail(
        resp => {

          switch (resp.status) {

            case 400:
              this.appError('Bitte geben Sie Benutzernamen und Passwort ein!');
              break;

            case 401:
            case 406:
              this.appError('Der Benutzername oder das Passwort ist falsch!');
              break;

            default:
              this.appError('Beim Überprüfen der Zugangsdaten ist ein Fehler aufgetreten. Der Dienst ist derzeit nicht erreichbar.');
          }
        }
      );
    },

    redirectToForgotPassword(){

      this.clearAppStatus();

      this.$router.push({name: 'forgot-password'});
    },
  },
}
</script>


<style scoped>
.grid {
  display: grid;
  grid-template-columns: auto minmax(120px, 20%);
  grid-column-gap: 12px;
  margin: 0 32px;
}
.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 6px;
}
#login > * {
  margin-bottom: 24px;
}
#login > h1 {
  margin-bottom: 48px;
  text-align: center;
}
.forgotPw {
  text-align: left;
  margin-left: 32px;
  font-size: 90%;
  color: #ccc;
}
@media only screen and (min-width: 640px) {
  .grid .button {
    margin-top: 27px;
  }
}
@media only screen and (max-width: 640px) {
  .grid,
  .grid2 {
    display: block;
    margin: 0;
  }
  .grid2 > div {
    margin-bottom: 12px;
  }
  .grid .button {
    text-align: center;
  }
  .forgotPw {
    margin: 0;
    text-align: center;
  }
}
#login .chartContainer {
  margin-top: 48px;
}
#login .chartContainer >>> h3 {
  display: none;
}
.username .titleHolder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>