<template>
  <QuestionWrapper
    :card="card"
  >
    <div>
      <h3>
        Wie viele Personen leben ständig in Ihrem Haushalt, Sie selbst eingeschlossen?
      </h3>

      <div>
        Denken Sie dabei bitte auch an alle im Haushalt lebenden Kinder.
      </div>

      <AppSelect
        v-model="size"
        nullCategory="--- bitte auswählen ---"
        :options="opts.map(o => o).filter(o => o !== 0)"
      />
    </div>

    <transition name="fade">
      <div
        v-if="size > 1"
      >
        <h3>
          Sind Sie in Ihrem Haushalt diejenige Person, die hauptsächlich für den Einkauf und den Haushalt zuständig ist?
        </h3>

        <div>
          <AppRadio
            v-for="(c, i) in cats"
            :key="'categoryHk-' + i"
            v-model="housekeeper"
            :value="c.val"
            :class="i === cats.length - 1 ? 'dk' : ''"
          >
            {{ c.label }}
          </AppRadio>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="size > 1"
      >
        <h3>
          Und sind Sie in Ihrem Haushalt diejenige Person, die <u>am meisten</u> zum Haushaltseinkommen beiträgt?
        </h3>

        <div>
          <AppRadio
            v-for="(c, i) in cats"
            :key="'categoryHh-' + i"
            v-model="householder"
            :value="c.val"
            :class="i === cats.length - 1 ? 'dk' : ''"
          >
            {{ c.label }}
          </AppRadio>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="size > 1"
      >
        <h3>
          Und wie viele Kinder unter 18 leben in Ihrem Haushalt?
        </h3>

        <AppSelect
          v-model="children"
          nullCategory="--- bitte auswählen ---"
          :options="childOpts"
        />
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="children > 0"
      >
        <h3>
          Bitte geben Sie für jedes dieser Kinder das Geburtsjahr an.
        </h3>

        <div>
          Beginnen Sie mit dem <u>ältesten</u> Kind unter 18 Jahren.
        </div>

        <div>
          <AppInput
            type="tel"
            v-model="birthyear1"
            placeholder="Geburtsjahr ältestes Kind (4-stellig)"
            maxlength="4"
          >
            ältestes Kind
          </AppInput>

          <AppInput
            v-if="children >= 2"
            type="tel"
            v-model="birthyear2"
            placeholder="Geburtsjahr zweitältestes Kind (4-stellig)"
            maxlength="4"
          >
            zweitältestes Kind
          </AppInput>

          <AppInput
            v-if="children >= 3"
            type="tel"
            v-model="birthyear3"
            placeholder="Geburtsjahr drittältestes Kind (4-stellig)"
            maxlength="4"
          >
            drittältestes Kind
          </AppInput>

          <AppInput
            v-if="children >= 4"
            type="tel"
            v-model="birthyear4"
            placeholder="Geburtsjahr viertältestes Kind (4-stellig)"
            maxlength="4"
          >
            viertältestes Kind
          </AppInput>

          <AppInput
            v-if="children >= 5"
            type="tel"
            v-model="birthyear5"
            placeholder="Geburtsjahr fünftältestes Kind (4-stellig)"
            maxlength="4"
          >
            fünftältestes Kind
          </AppInput>

          <AppInput
            v-if="children >= 6"
            type="tel"
            v-model="birthyear6"
            placeholder="Geburtsjahr sechstältestes Kind (4-stellig)"
            maxlength="4"
          >
            sechstältestes Kind
          </AppInput>
        </div>

      </div>
    </transition>
    
    <AppButton
      @click="submit"
      cls="material"
    >
      Speichern
    </AppButton>

    <!-- <div
      style="position: fixed; top: 200px; right: 20px; width: 150px; background: #ccc;"
    >
      size: {{size}} <br />
      householder: {{householder}} <br />
      housekeeper: {{housekeeper}} <br />
      children: {{children}} <br />
      birthyear1: {{birthyear1}} <br />
      birthyear2: {{birthyear2}} <br />
      birthyear3: {{birthyear3}} <br />
      birthyear4: {{birthyear4}} <br />
      birthyear5: {{birthyear5}} <br />
      birthyear6: {{birthyear6}}
    </div> -->

  </QuestionWrapper>
</template>


<script>
import QuestionWrapper from '../QuestionWrapper'

export default {

  name: 'Household',

  components: {
    QuestionWrapper,
  },

  data(){
    return {
      size: this.details.household_size,
      householder: this.details.householder,
      housekeeper: this.details.housekeeper,
      children: this.details.number_children,
      birthyear1: this.details.birthyear_1,
      birthyear2: this.details.birthyear_2,
      birthyear3: this.details.birthyear_3,
      birthyear4: this.details.birthyear_4,
      birthyear5: this.details.birthyear_5,
      birthyear6: this.details.birthyear_6,
      cats: [
        {val: 1, label: 'ja'},
        {val: 2, label: 'nein'},
        {val: 3, label: 'keine Angabe'},
      ],
      opts: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,{value: 20, label: '20 oder mehr'}],
    }
  },

  props: {
    details: Object,
    card: Object,
  },

  computed: {

    scope(){
      return 'save-' + this.$options.name;
    },

    childOpts(){

      if (!this.size)
        return [];

      let opts = [];
      
      for (let i = 0; i < this.size; i++) {

        opts.push(this.opts[i]);
      }

      if (this.size === 20)
        opts.push(this.opts[this.opts.length - 1])

      return opts;
    },
  },

  watch: {

    size(newVal, oldVal){

      //console.log('onSizeChange', newVal);

      if (newVal === null)
        return;

      if (newVal === 1) {

        this.householder = 1;

        this.housekeeper = 1;

        this.children = 0;
      }

      if (oldVal === 1) {

        this.householder = null;

        this.housekeeper = null;

        this.children = '';
      }
    },

    children(newVal){

      //console.log('onChildrenChange', newVal);

      if (newVal > 6)
        return;

      for (let i = 6; i > 0; i--) {

        if (newVal <= i) {

          this['birthyear' + i] = null;
        }
      }
    },
  },

  methods: {

    submit(){

      if (!this.size) {

        this.$swal({
          text: 'Bitte wählen Sie eine Antwortkategorie aus.',
        });

        return false;
      }

      if (!this.householder || ! this.housekeeper || this.children === '') {

        this.$swal({
          text: 'Bitte wählen Sie für jede Frage eine Antwortkategorie aus.',
        });

        return false;
      }

      if (this.children > 0) {

        for (let i = this.children; i > 0; i--) {

          if (!this['birthyear' + i]) {

            this.$swal({
              text: 'Bitte geben Sie für jedes im Haushalt lebende Kind das Geburtsjahr an.',
            });

            return false;
          }

          if (this['birthyear' + i] < 1900 || this['birthyear' + i] > new Date().getFullYear()) {

            this.$swal({
              text: 'Bitte geben Sie für jedes im Haushalt lebende Kind das Geburtsjahr als vierstellige Jahreszahl an.',
            });

            return false;
          }
        }
      }

      this.appWorking(this.scope);
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'submit-household',
        data: {
          size: this.size,
          householder: this.householder,
          housekeeper: this.housekeeper,
          children: this.children,
          birthyears: [this.birthyear1, this.birthyear2, this.birthyear3, this.birthyear4, this.birthyear5, this.birthyear6],
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {
      
          this.appSuccess('Ihre Antwort wurde erfolgreich gespeichert.', this.scope);

          this.$emit('cardSaved', this.$options.name);
        }
      )
      .fail(
        resp => {
      
          this.appError('Beim Speichern Ihrer Antwort ist ein Fehler aufgetreten.', null, this.scope);
        }
      );
    },
  },
}
</script>


<style scoped>
.qColl .qCont > *:not(.button) {
  padding: 0;
}
.qColl .qCont > div > * {
  padding: 12px;
}
.qColl .qCont .select {
  max-width: 400px;
  margin: 0 auto;
}
.qColl .qCont .input {
  display: grid;
  grid-template-columns: minmax(20%, 144px) auto;
  grid-column-gap: 6px;
  margin-bottom: 6px;
}
</style>