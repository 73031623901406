<template>
  <div id="overview">

    <h1>
      Übersicht
    </h1>

    <h3>
      Aktuelle Umfragen
    </h3>

    <div
      v-if="open.length"
      class="grid"
    >
      <Survey
        v-for="(s, i) in open"
        :key="'openSurvey-' + i"
        :params="s"
        status="open"
        :style="i === 0 ? 'grid-column: span 2;' : ''"
      />
    </div>

    <div
      v-else
    >
      <div
        v-if="scopedStatus['get-surveys'] === 1"
      >
        Aktuelle Umfragen werden gesucht...
      </div>
      <div
        v-else
      >
        Derzeit stehen keine offenen Umfragen zur Verfügung.
      </div>
    </div>

    <h3>
      Aktuelle Profilfragen
    </h3>

    <div
      v-if="tabLabels.length"
      class="profile"
    >
      <AppTabs
        v-model="show"
        :labels="tabLabels"
        :class="isFading ? 'fadeOutTab' : ''"
        @change="clearAppStatus()"
      >
        <component
          v-if="show"
          :is="selectedCard.vue_component_name"
          :details="details"
          :card="selectedCard"
          @cardSaved="initiateTabFadeOut"
        />
      </AppTabs>
    </div>

    <div
      v-else
    >
      Perfekt, Sie haben alle Profilfragen beantwortet!
    </div>

  </div>
</template>


<script>
import getSurveyMixin from '@/components/mixins/getSurveys'

import Survey from './survey/Survey'

import Salutation from './profile/address/Salutation'
import Contact from './profile/address/Contact'
import PersonDetails from './profile/address/PersonDetails'
import Residence from './profile/address/Residence'
import Income from './profile/education/Income'
import LabourUnion from './profile/education/LabourUnion'
import Employment from './profile/education/Employment'
import MaritalStatus from './profile/living/MaritalStatus'
import Household from './profile/living/Household'
import ElectionDecision from './profile/politics/ElectionDecision'
import ElectionIntention from './profile/politics/ElectionIntention'
import Religion from './profile/politics/Religion'

export default {

  name: 'Overview',

  components: {
    Survey,
    Salutation,
    Contact,
    PersonDetails,
    Residence,
    Income,
    LabourUnion,
    Employment,
    MaritalStatus,
    Household,
    ElectionDecision,
    ElectionIntention,
    Religion,
  },

  data(){
    return {
      cards: [],
      details: {},
      show: null,
      isFading: false,
    }
  },

  computed: {

    tabLabels(){

      return this.cards.map(
        c => {
          return {
            val: c.vue_component_name,
            label: c.card_name,
          };
        }
      );
    },

    selectedCard(){

      return !this.show ? {} : this.cards.find(c => c.vue_component_name === this.show);
    },
  },

  mixins: [getSurveyMixin],

  mounted(){

    this.getSurveys();

    this.getOpenProfileCards();
  },

  methods: {

    getOpenProfileCards(){

      this.appWorking('get-cards');
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'get-unanswered-profile-cards',
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.clearAppStatus('get-cards');
      
          let unwrap = readJson(resp);

          this.cards = unwrap.cards.filter(c => !c.has_value || c.needs_refresh);

          this.details = unwrap.details;

          this.showFirstCard();
        }
      )
      .fail(
        resp => {
      
          this.appError('Ein Fehler ist aufgetreten.', null, 'get-cards');
        }
      );
    },

    showFirstCard(){

      if (this.cards.length)
        this.show = this.cards[0].vue_component_name;
      else
        this.show = null;
    },

    initiateTabFadeOut(name){

      window.setTimeout(
        () => { this.startFadeOut() },
        1600
      );
    },

    startFadeOut(){

      this.isFading = true;

      window.setTimeout(
        () => { this.removeTab() },
        1200
      );
    },

    removeTab(){

      let idx = this.cards.map(c => c.vue_component_name).indexOf(name);

      this.cards.splice(idx, 1);

      this.showFirstCard();

      this.isFading = false;
    },

  },
}
</script>


<style scoped>
#overview > * {
  margin: 0 0 24px 0;
}
#overview > h1 {
  margin-bottom: 48px;
}
#overview > h3 {
  margin-top: 48px;
}
#overview > .grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 12px;
}
@media only screen and (max-width: 960px) {
  #overview > .grid {
    grid-column-gap: 12px;
  }
}
@media only screen and (max-width: 640px) {
  #overview > .grid {
    display: block;
  }
  #overview > .grid > div {
    margin: 12px 0;
  }
}
#overview .profile >>> .qColl .header {
  display: none;
}
#overview .profile .fadeOutTab >>> .qColl {
  opacity: 0;
  transition: opacity 1.2s ease-in;
}
</style>