<template>
  <div id="education">
    
    <h3>
      Ausbildung &amp; Beruf
    </h3>

    <AppTabs
      v-model="show"
      :labels="cats"
      @change="clearAppStatus()"
      class="bordered"
    >
      <div
        v-for="(card, i) in filteredCards"
        :key="'educationCard-' + i"
      >
        <component
          v-if="show === card.card_name"
          :is="card.vue_component_name"
          :details="details"
          :card="card"
          @cardSaved="$emit('refreshDetails')"
        />
      </div>
    </AppTabs>

  </div>
</template>


<script>
// attention! which cards are present in this section is controlled by which components are imported
import Income from './education/Income'
import LabourUnion from './education/LabourUnion'
import Employment from './education/Employment'

export default {

  name: 'Education',

  components: {
    Income,
    LabourUnion,
    Employment,
  },

  data(){
    return {
      filteredCards: [],
      show: null,
      cats: [],
    }
  },

  props: {
    cards: Array,
    details: Object,
  },

  mounted(){

    this.filterCards();
  },

  methods: {

    filterCards(){

      this.filteredCards = this.cards.map(c => c).filter(
        c => {

          return c.vue_component_name in this.$options.components;
        }
      );

      this.cats = this.filteredCards.map(
        c => c.card_name
      );

      this.show = this.cats[0];
    },
  },
}
</script>


<style scoped>
#education > * {
  margin: 0 0 24px 0;
}
#education > h1 {
  margin-bottom: 48px;
}
</style>