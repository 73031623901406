<template>
  <div id="userBox">
    
    <StatusIcon
      v-if="scopedStatus.userBox === 1"
      scope="userBox"
    />

    <h3>
      {{ name }}
    </h3>

    <div>
      {{ balance }} Punkte
    </div>

  </div>
</template>


<script>

export default {

  name: 'UserBox',

  data(){
    return {
      name: '',
      balance: 0,
    }
  },

  created(){

    this.getUserInfos();
  },

  methods: {

    getUserInfos(){

      this.appWorking('userBox');
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'get-member-display-infos',
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.clearAppStatus('userBox');
      
          let m = readJson(resp);

          this.name = m.name;

          this.balance = m.balance;

          this.$store.dispatch('storeBalance', m.balance)
        }
      )
      .fail(
        resp => {
      
          this.appError('Beim Abrufen der Teilnehmer-Informationen ist ein Fehler aufgetreten.');
        }
      );

    },
  },
}
</script>


<style scoped>
#userBox {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
  padding: 12px 6px;
}
#userBox > .appStatusIcon {
  position: absolute;
  width: 100%;
  left: 0;
  background: #efefee;
  text-align: center;
}
</style>