export default {

  data(){
    return {
      sort: {
        key: null,
        direction: null
      },
    }
  },

  methods: {

    sortResults(dataName, key){

      let newArr = this[dataName].sort(
        (a, b) => {
          var x = a[key] !== null ? a[key] : '';
          var y = b[key] !== null ? b[key] : '';
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        }
      );

      if (this.sort.key === key && this.sort.direction === 1) {

        newArr.reverse();

        this.sort.direction = -1;

      } else {

        this.sort.direction = 1;
      }

      this[dataName] = newArr;

      this.sort.key = key;
    },

  },
}

