import Vue from 'vue'
import Vuex from 'vuex'

import { appStatusStore } from '@forsa/vue-webapp-status'

// store-section for the request-url
const urlStore = {

  state: {

    urls: {},
  },

  getters: {

    urls(state){

      return state.urls;
    },
  },

  mutations: {

    STORE_URL(state, payload){

      state.urls[payload.key] = payload.value;
    },
  },

  actions: {

    storeUrl(context, payload){

      if ('key' in payload && 'value' in payload)
        context.commit('STORE_URL', payload);
    },
  },
}

// store-section for the currently navigated page
const navigationStore = {

  state: {
    loggedIn: false,
    section: 'overview',
    balance: 0,
  },

  getters: {

    getBalance(state){

      return state.balance;
    },

    getLoginState(state){

      return state.loggedIn;
    },
  },

  mutations: {

    SET_LOGIN_STATE(state, payload){

      state.loggedIn = payload;
    },

    SET_SELECTED_SECTION(state, payload){

      state.section = payload;
    },

    STORE_BALANCE(state, payload){

      state.balance = payload;
    },
  },

  actions: {

    setLoginState(context, payload){

      context.commit('SET_LOGIN_STATE', payload);
    },

    setSection(context, payload){

      context.commit('SET_SELECTED_SECTION', payload);
    },

    storeBalance(context, payload){

      context.commit('STORE_BALANCE', payload);
    },
  },
}

// store for the member-action-counter
const actionCounterStore = {

  state: {
    surveys: 0,
    profileQuestions: 0,
  },

  getters: {

    getCurrentSurveyCount(state){

      return state.surveys;
    },

    getCurrentProfileCount(state){

      return state.profileQuestions;
    },
  },

  mutations: {

    SET_SURVEY_COUNT(state, payload){

      state.surveys = payload;
    },

    SET_PROFILE_QUESTION_COUNT(state, payload){

      state.profileQuestions = payload;
    },
  },

  actions: {

    setSurveyCount(context, payload){

      context.commit('SET_SURVEY_COUNT', payload);
    },

    setProfileCount(context, payload){

      context.commit('SET_PROFILE_QUESTION_COUNT', payload);
    },
  },
}

Vue.use(Vuex)

const store = new Vuex.Store({

  modules: {
    navigationStore,
    urlStore,
    appStatusStore,
    actionCounterStore,
  },
})

export default store
