<template>
  <AppTable id="payments">
    
    <thead>
      <tr>
        <th
          @click="sortResults('payments', 'date')"
          :class="sort.key === 'date' ? (sort.direction === 1 ? 'up' : 'down') : ''"
        >
          Datum
        </th>
        <th
          @click="sortResults('payments', 'amount')"
          :class="sort.key === 'amount' ? (sort.direction === 1 ? 'up' : 'down') : ''"
        >
          Betrag
        </th>
        <th
          class="no-sort"
          colspan="3"
        >
          Beschreibung
        </th>
      </tr>
    </thead>

    <tbody>
      <!-- displayed payments -->
      <tr
        v-for="(p, i) in payments"
        :key="'payment-' + i"
      >
        <td>
          {{ moment(p.date).format('DD.MM.YYYY') }}
        </td>
        <td>
          {{ p.amount }}
        </td>
        <td
          v-if="p.committed"
          colspan="3"
          v-html="getDescription(p)"
        >
        </td>
        <td
          v-if="!p.committed"
          colspan="3"
        >
          Auszahlung vorgemerkt
        </td>
      </tr>

      <!-- searching for credits -->
      <tr
        v-if="scopedStatus['paymentHistory'] === 1"
      >
        <td colspan="5">
          Auszahlungen werden gesucht...
        </td>
      </tr>

      <!-- no payments -->
      <tr
        v-if="!payments.length && scopedStatus['paymentHistory'] !== 1"
      >
        <td colspan="5">
          Es sind noch keine Auszahlungen vorhanden
        </td>
      </tr>
    </tbody>
    
  </AppTable>
</template>


<script>
import sortTableMixin from '@/components/mixins/sortTable'

export default {

  name: 'PaymentHistory',

  data(){
    return {
      payments: [],
    }
  },

  mixins: [sortTableMixin],

  mounted(){

    this.getPayments();
  },

  methods: {

    getPayments(){

      this.appWorking('paymentHistory');
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'get-payment-history',
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {
      
          this.payments = readJson(resp);

          this.sort.key = 'date';

          this.sort.direction = 2;

          this.clearAppStatus('paymentHistory');
        }
      )
      .fail(
        resp => {
      
          this.appError('Beim Abruf der Auszahlungs-Historie ist ein Fehler aufgetreten.');
        }
      );
    },

    getDescription(payment){

      let msg = '';

      switch (payment.type) {

        case 2:
          msg = 'Losgutschein für <b>Aktion Mensch</b> <br />' + 
                'Losnummer: ' + payment.code + '<br />' +
                'Aktivierungscode: ' + payment.pin + '<br />' +
                '<a href="https://www.aktion-mensch.de/lotterie/service/los-aktivieren/?code=' + payment.code + '" target="_blank">Jetzt einlösen</a>';
          break;

        case 3:
          msg = 'Universal-Einkaufsgutschein von <b>cadooz</b> <br />' + 
                'Gutscheincode: ' + payment.code + '<br />' + 
                '<a href="' + payment.url + '" target="_blank">Jetzt einlösen</a>';
          break;

        case 4:
          msg = 'Spende an <b>Unicef</b> <br  />' + 
                'Wir haben in Ihrem Namen <b>' + (Math.round(payment.amount) / 100).toFixed(2) + ' Euro</b> an Unicef gespendet.'

          if (payment.url)
            msg += '<br /><a href="' + payment.url + '" target="_blank">Spendenbescheinigung ansehen</a>';

          break;

        case 5:
          msg = 'Spende an <b>SOS Kinderdorf</b> <br  />' + 
                'Wir haben in Ihrem Namen <b>' + (Math.round(payment.amount) / 100).toFixed(2) + ' Euro</b> an SOS Kinderdorf gespendet.';

          if (payment.url)
            msg += '<br /><a href="' + payment.url + '" target="_blank">Spendenbescheinigung ansehen</a>';

          break;
      }

      return msg;
    },

  },
}
</script>


<style>
#payments tr > td > a {
  text-decoration: underline;
}
</style>


<style scoped>
#payments {
  margin: 12px 0;
}
#payments tr > td:nth-of-type(3) {
  text-align: left;
}
</style>