<template>
  <div
    :class="'survey ' + status"
  >
    
    <div class="header">
      <div class="date">
        {{ date }}
      </div>
      {{ title }}
    </div>

    <div class="grid">
      <div>
        Thema:
      </div>
      <div>
        {{ params.description }}
      </div>

      <div
        v-if="params.incentive"
      >
        Gutschrift:
      </div>
      <div
        v-if="params.incentive"
      >
        {{ params.incentive }} Punkte
      </div>

      <div
        v-if="params.duration"
      >
        Dauer:
      </div>
      <div
        v-if="params.duration"
      >
        {{ params.duration }} Minuten
      </div>
    </div>

    <div
      v-if="status === 'open'"
      class="link"
    >
      <a
        :href="params.url"
      >
        <span tabindex="-1">
          Jetzt Umfrage starten
        </span>
      </a>
    </div>



  </div>
</template>


<script>
export default {

  name: 'Survey',

  props: {
    params: Object,
    status: String,
  },

  computed: {

    title(){

      if (this.params.status === 1)
        return 'Vielen Dank für Ihre Teilnahme';

      if ([0,2,3].includes(this.params.status)) {

        if (this.params.active && this.moment(this.params.end_date).valueOf() > this.moment().valueOf())
          return 'Eine neue Umfrage steht für Sie zur Verfügung';
        else
          return 'Diese Umfrage haben Sie leider verpasst';
      }

      return 'Leider gehörten Sie dieses Mal nicht zu unserer Zielgruppe';
    },

    date(){

      let date = this.status === 'open' ? this.params.invitation_date : this.params.status_date;

      return this.moment(date).format('DD.MM.YYYY');
    }
  },
}
</script>


<style scoped>
.survey {
  /*box-shadow: 0 1px 2px rgba(0,0,0,0.12), 0 0 1px rgba(0,0,0,0.24);*/
  border: 1px solid #ccc;
}
.survey.closed {
  font-size: 90%;
}
.survey > * {
  font-family: Georgia, 'Times New Roman', Times, serif;
}
.survey .header {
  padding: 48px 6px 12px 6px;
  background: #d8d8d5;
  font-size: 150%;
  text-align: center;
  position: relative;
}
.survey .date {
  font-family: 'Nunito Sans', Helvetica, Arial, sans-serif;
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 12px;
  color: #636355;
}
.survey .grid {
  margin: 24px 6px 0 6px;
  display: grid;
  grid-template-columns: minmax(144px, 40%) auto;
  grid-column-gap: 12px;
  grid-row-gap: 3px;
  align-items: baseline;
  padding-bottom: 12px;
}
@media only screen and (max-width: 960px) {
  .survey .grid {
    grid-template-columns: minmax(96px, 30%) auto;
  }
}
@media only screen and (max-width: 640px) {
  .survey .grid {
    grid-template-columns: minmax(84px, 25%) auto;
  }
}
.survey .grid > div:nth-of-type(2n) {
  font-size: 125%;
}
.survey .grid > div:nth-of-type(2n+1) {
  text-align: right;
}
.survey .link {
  text-align: center;
}
.survey .link a > span {
  margin: 12px auto;
  padding: 12px 36px;
  max-width: 300px;
  display: inline-block;
  background: #e63223;
  color: #fff;
  font-size: 125%;
  font-weight: bold;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: background 0.1s ease-out;
  user-select: none;
}
.survey .link a:focus > span,
.survey .link a > span:hover {
  background: #b72115;
}
.survey .link a:active {
  box-shadow: 0 1px 2px rgba(0,0,0,0.12), 0 0 1px rgba(0,0,0,0.24);
}
</style>