<template>
  <div id="help">

    <h1>
      Hilfe
    </h1>

    <AppTabs
      v-model="show"
      :labels="[{val: 'contact', label: 'Kontakt'}, {val: 'faq', label: 'Häufige Fragen'}, {val: 'change-password', label: 'Passwort ändern'}]"
      @change="clearAppStatus()"
    >
      <Contact
        v-if="show === 'contact'"
      />

      <Faq
        v-if="show === 'faq'"
      />

      <ChangePassword
        v-if="show === 'change-password'"
      />

    </AppTabs>

  </div>
</template>


<script>
import Contact from './help/Contact'
import Faq from './help/Faq'
import ChangePassword from './help/ChangePassword'

export default {

  name: 'Help',

  components: {
    Contact,
    Faq,
    ChangePassword,
  },

  data(){
    return {
      show: 'contact',
    }
  },
}
</script>


<style scoped>
#help > * {
  max-width: 800px;
}
#help > h1 {
  margin-bottom: 48px;
}
#help .tabs > .content > * {
  padding: 12px;
}
</style>