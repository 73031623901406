<template>
  <div id="faq">

    <h3>
      Wann und wie erfolgt die Auszahlung meiner gesammelten Punkte?
    </h3>

    <div>
      Die Auszahlung der Bonuspunkte erfolgt zweimal jährlich auf die von Ihnen angegebene Art und Weise. Derzeit stehen vier Auszahlungsvarianten zur Auswahl:
      <ul>
        <li>Universal Einkaufsgutschein von <b>cadooz</b></li>
        <li>Lose für <b>Aktion Mensch</b></li>
        <li>Spende an <b>Unicef</b></li>
        <li>Spende an <b>SOS-Kinderdorf</b></li>
      </ul>
      Welche dieser vier Varianten Sie bevorzugen, können Sie im Bereich <b>Kontoübersicht</b> angeben.
      <br>
      <br>Der Versand der Gutscheine bzw. Losgutscheine erfolgt dann kurze Zeit nach dem angegebenen Datum. 
    </div>


    <h3>
      Gibt es eine Mindestpunktzahl für die Auszahlung?
    </h3>

    <div>
      Ja, die Auszahlung erfolgt immer schrittweise: Für die Lose der <b>Aktion Mensch</b> erhalten Sie pro 750 Punkte einen Losgutschein, bei allen anderen Varianten erfolgt die Auszahlung in 500-Punkte-Schritten. Ein Punkt hat dabei den Gegenwert von einem Euro-Cent.
      <br>
      <br>Sollten Sie zum Auszahlungstermin weniger als die erforderliche Punktzahl gesammelt haben, gehen Ihnen diese selbstverständlich nicht verloren, sondern werden in den nächsten Auszahlungszeitraum übernommen. Dasselbe gilt natürlich für die Punkte, die nach der Auszahlung noch übrig sind.
    </div>


    <h3>
      Warum ist mein Punktestand nicht aktuell?
    </h3>

    <div>
      In den meisten Fällen werden die Punkte direkt nach der erfolgreichen Teilnahme an einer Umfrage gut geschrieben und werden dann auch im Bereich <b>Kontoübersicht</b> angezeigt. Bei einigen Umfragen nutzen wir jedoch ein anderes technisches Erhebungssystem. In diesen Fällen kann es vorkommen, dass die Gutschrift erst erfolgt, wenn die Feldzeit abgeschlossen ist, wir also alle Antworten aller Befragten erhalten haben.
      <br>
      <br>Sollten Sie auch nach einigen Tagen noch eine erwartete Gutschrift vermissen, hilft Ihnen unser forsa.omninet Support-Team gern weiter.
    </div>


    <h3>
      Warum werde ich nur selten zu neuen Umfragen eingeladen?
    </h3>

    <div>
      Die meisten Umfragen richten sich nur an spezielle Zielgruppen. Dies können je nach Thema z.B. Personen aus einer bestimmten Region sein oder Personen bestimmter Altersgruppen. Um Ihnen die Mühe zu ersparen, jedes Mal dieselben Statistik-Fragen zu beantworten, um zu prüfen, ob Sie zur gesuchten Zielgruppe gehören, filtern wir das Teilnehmerfeld bereits im Vorfeld anhand der angegebenen Profil-Informationen. Daher ist es besonders wichtig, dass Ihre Profil-Informationen vollständig und aktuell vorliegen&nbsp;– nur so können wir Sie gezielt für die Umfragen einladen, die sich an Ihre Zielgruppe richten.
    </div>


    <h3>
      Wie kann ich meinen Benutzernamen ändern?
    </h3>

    <div>
      Möchten Sie Ihren Login-Namen für forsa.omninet ändern, wenden Sie sich bitte telefonisch an unser forsa.omninet Support-Team.
    </div>


    <h3>
      Wie kann ich meine Teilnahme an forsa.omninet widerrufen?
    </h3>

    <div>
      Forsa-Umfragen tragen zur politischen und gesellschaftlichen Diskussion in Deutschland bei, indem Sie ein möglichst breites, repräsentatives Meinungsbild widerspiegeln. Deshalb sind Ihre Antworten als Teilnehmer von forsa.omninet auch so wichtig.
      <br>
      <br>Sollten Sie dennoch Ihre Teilnahme an Umfragen in forsa.omninet widerrufen wollen, klicken Sie bitte <router-link to="/portal/help/quit">hier</router-link>.
    </div>


  </div>
</template>


<script>

export default {

  name: 'FAQ',
}
</script>


<style scoped>
#faq > * {
  margin-bottom: 24px;
  hyphens: auto;
  max-width: 800px;
}
#faq > h3 {
  margin-top: 48px;
}
ul {
  margin: 12px;
}
</style>