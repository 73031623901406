<template>
  <QuestionWrapper
    :card="card"
  >
    
    <div>
      <h3>
        Welchen höchsten Schul- bzw. Hochschulabschluss haben Sie?
      </h3>

      <div>
        <AppRadio
          v-for="(c, i) in catsEdu"
          :key="'categoryEdu-' + i"
          v-model="education"
          :value="c.val"
          :class="i === catsEdu.length - 1 ? 'dk' : ''"
        >
          {{ c.label }}
        </AppRadio>
      </div>
    </div>

    <transition name="fade">
      <div
        v-if="occupationVisible"
      >
        <h3>
          Sind Sie zurzeit erwerbstätig?
        </h3>

        <div>
          Unter Erwerbstätigkeit wird jede <b>bezahlte</b> bzw. <b>mit einem Einkommen verbundene</b> Tätigkeit verstanden, egal welchen zeitlichen Umfang sie hat.
        </div>

        <div>
          <AppRadio
            v-for="(c, i) in catsOcc"
            :key="'categoryOcc-' + i"
            v-model="occupation"
            :value="c.val"
            :class="i === catsOcc.length - 1 ? 'dk' : ''"
          >
            {{ c.label }}
          </AppRadio>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="nonworkingVisible"
      >
        <h3>
          Zu welcher der folgenden Gruppen gehören Sie?
        </h3>

        <div>
          <AppRadio
            v-for="(c, i) in catsNon"
            :key="'categoryNon-' + i"
            v-model="nonworking"
            :value="c.val"
            :class="c.val === 1 ? 'hidden' : i === catsNon.length - 1 ? 'dk' : ''"
          >
            {{ c.label }}
          </AppRadio>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="careerVisible"
      >
        <h3>
          Welche berufliche Stellung trifft derzeit auf Sie zu?
        </h3>

        <div>
          <AppRadio
            v-for="(c, i) in catsCar"
            :key="'categoryCar-' + i"
            v-model="career"
            :value="c.val"
            :class="i === catsCar.length - 1 ? 'dk' : ''"
          >
            {{ c.label }}
          </AppRadio>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="employeeVisible"
      >
        <h3>
          Sind Sie Angestellte / Angestellter &hellip;
        </h3>

        <div>
          <AppRadio
            v-for="(c, i) in catsEmp"
            :key="'categoryEmp-' + i"
            v-model="employee"
            :value="c.val"
            :class="i === catsEmp.length - 1 ? 'dk' : ''"
          >
            {{ c.label }}
          </AppRadio>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="officialVisible"
      >
        <h3>
          Sind bzw. waren Sie im einfachen, mittleren, gehobenen oder höheren Dienst tätig?
        </h3>

        <div>
          <AppRadio
            v-for="(c, i) in catsOff"
            :key="'categoryOff-' + i"
            v-model="official"
            :value="c.val"
            :class="i === catsOff.length - 1 ? 'dk' : ''"
          >
            {{ c.label }}
          </AppRadio>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="workerVisible"
      >
        <h3>
          Welches der folgenden Tätigkeitsmerkmale trifft bzw. traf auf Ihre Arbeit am ehesten zu?
        </h3>

        <div>
          <AppRadio
            v-for="(c, i) in catsWor"
            :key="'categoryWor-' + i"
            v-model="worker"
            :value="c.val"
            :class="i === catsWor.length - 1 ? 'dk' : ''"
          >
            {{ c.label }}
          </AppRadio>
        </div>
      </div>
    </transition>

    <div class="hidden">
      <h3>
        Auto-Vercodung: Erwerbstätigkeit
      </h3>

      <div>
        <AppRadio
          v-model="employment"
          :value="1"
          label="ja"
        />
        <AppRadio
          v-model="employment"
          :value="2"
          label="nein"
        />
      </div>
    </div>

    <AppButton
      @click="submit"
      cls="material"
    >
      Speichern
    </AppButton>

    <!-- <div
      style="position: fixed; top: 200px; right: 20px; width: 150px; background: #ccc;"
    >
      education: {{education}} <br />
      occupation: {{occupation}} <br />
      nonworking: {{nonworking}} <br />
      career: {{career}} <br />
      employee: {{employee}} <br />
      official: {{official}} <br />
      worker: {{worker}} <br />
      employment: {{employment}}
    </div> -->

  </QuestionWrapper>
</template>


<script>
import QuestionWrapper from '../QuestionWrapper'

export default {

  name: 'Employment',

  components: {
    QuestionWrapper,
  },

  data(){
    return {
      education: this.details.education,
      catsEdu: [
        {val: 0, label: 'noch Schülerin / Schüler'},
        {val: 1, label: 'ohne Haupt- / Volksschulabschluss'},
        {val: 2, label: 'Haupt- / Volksschulabschluss'},
        {val: 3, label: 'Realschulabschluss (Mittlere Reife)'},
        {val: 4, label: 'Abschluss der Polytechnischen Oberschule (8. bzw. 10. Klasse)'},
        {val: 5, label: 'Fachhochschulreife'},
        {val: 6, label: 'allgemeine oder fachgebundene Hochschulreife / Abitur'},
        {val: 7, label: 'Fach- / Hochschulstudium'},
        {val: 8, label: 'anderer Schulabschluss'},
        {val: 9, label: 'keine Angabe'},
      ],
      occupation: this.details.occupation,
      catsOcc: [
        {val: 1, label: 'voll erwerbstätig'},
        {val: 2, label: 'teilzeitbeschäftigt'},
        {val: 3, label: 'in Altersteilzeit'},
        {val: 4, label: 'geringfügig erwerbstätig, in einem Mini-Job'},
        {val: 5, label: 'in einem "Ein-Euro-Job" (Arbeitsgelegenheit) bei Bezug von Bürgergeld / Arbeitslosengeld 2'},
        {val: 6, label: 'gelegentlich oder unregelmäßig beschäftigt'},
        {val: 7, label: 'in einer beruflichen Ausbildung / Lehre'},
        {val: 8, label: 'in Umschulung'},
        {val: 9, label: 'im Wehrdienst / Bundesfreiwilligendienst'},
        {val: 10, label: 'nicht erwerbstätig'},
        {val: 11, label: 'keine Angabe'},
      ],
      nonworking: this.details.nonworking,
      catsNon: [
        {val: 1, label: 'Schülerin / Schüler'},
        {val: 2, label: 'Studentin / Student'},
        {val: 3, label: 'in Rente, in Pension, im Vorruhestand'},
        {val: 4, label: 'arbeitslos'},
        {val: 5, label: 'Hausfrau, Hausmann'},
        {val: 6, label: 'in Mutterschafts-, Erziehungsurlaub, Elternzeit oder sonstiger Beurlaubung'},
        {val: 7, label: 'aus anderen Gründen nicht erwerbstätig'},
        {val: 8, label: 'nichts davon trifft zu'},
        {val: 9, label: 'keine Angabe'},
      ],
      career: this.details.career,
      catsCar: [
        {val: 7, label: 'in Ausbildung'},
        {val: 5, label: 'Angestellte / Angestellter'},
        {val: 6, label: 'Arbeiterin / Arbeiter'},
        {val: 2, label: 'Freiberuflich tätig'},
        {val: 4, label: 'Beamtet tätig als Richterin / Richter, Berufsoldatin / -soldat'},
        {val: 3, label: 'Selbständig tätig im Handel, Handwerk, Industrie, Dienstleistung'},
        {val: 1, label: 'Selbständig tätig als Landwirtin / Landwirt bzw. Genossenschaftsbäuerin / -bauer'},
        {val: 8, label: 'mithelfende Familienangehörige'},
        {val: 9, label: 'sonstiges / keine Angabe'},
      ],
      employee: this.details.employee,
      catsEmp: [
        {val: 1, label: 'in ausführender Tätigkeit nach allgemeiner Anweisung'},
        {val: 2, label: 'in qualifizierter Tätigkeit nach Anweisung'},
        {val: 3, label: 'in eigenständiger Leistung in verantwortlicher Tätigkeit bzw. mit Fachverantwortung für Personal'},
        {val: 4, label: 'mit umfassenden Führungsaufgaben und Entscheidungsbefugnissen'},
        {val: 5, label: 'keine Angabe'},
      ],
      official: this.details.official,
      catsOff: [
        {val: 1, label: 'einfacher Dienst'},
        {val: 2, label: 'mittlerer Dienst'},
        {val: 3, label: 'gehobener Dienst'},
        {val: 4, label: 'höherer Dienst, Richterin / Richter'},
        {val: 5, label: 'keine Angabe'},
      ],
      worker: this.details.worker,
      catsWor: [
        {val: 1, label: 'ungelernt'},
        {val: 2, label: 'angelernt'},
        {val: 3, label: 'Facharbeiterin / Facharbeiter'},
        {val: 4, label: 'Vorarbeiterin / Vorarbeiter, Kolonnenführerin / Kolonnenführer'},
        {val: 5, label: 'Meisterin / Meister, Polierin / Polier, Brigadierin / Brigadier'},
        {val: 6, label: 'keine Angabe'},
      ],
      employment: this.details.employment,
    }
  },

  props: {
    details: Object,
    card: Object,
  },

  computed: {

    scope(){
      return 'save-' + this.$options.name;
    },

    occupationVisible(){
      return this.education > 0;
    },

    nonworkingVisible(){
      return [2, 4, 6, 8, 10].includes(this.occupation);
    },

    careerVisible(){
      return [1, 3, 7].includes(this.occupation) || ([2, 4, 6, 8].includes(this.occupation) && [6, 8].includes(this.nonworking));
    },

    employeeVisible(){
      return this.career === 5;
    },

    officialVisible(){
      return this.career === 4;
    },

    workerVisible(){
      return this.career === 6;
    },

  },

  watch: {

    education(newVal){

      //console.log('onEducationChange', newVal);
      
      if (newVal === 0) {

        this.occupation = null;

        this.nonworking = 1;

        this.career = null;

        this.employee = null;

        this.official = null;

        this.worker = null;

        this.employment = 2;
      }
    },

    occupation(newVal){

      //console.log('onOccupationChange', newVal);

      if (newVal === null)
        return;

      if (newVal === false) {

        this.occupation = null;

        return;
      }

      if (![2, 4, 6, 8, 10].includes(newVal)) {

        this.nonworking = null;

        this.employment = 1;
      }

      if (![1, 3, 7].includes(newVal)) {

        this.career = null;
      }

      if ([9, 10, 11].includes(newVal)) {

        this.employment = 2;
      }

      if ([2, 4, 6, 8, 10].includes(newVal) && this.nonworking === 1) {

        this.nonworking = null;
      }
    },

    nonworking(newVal){

      //console.log('onNonworkingChange', newVal);

      if (newVal === null)
        return;

      if (newVal === false) {

        this.nonworking = null;

        return;
      }

      if (![6, 8].includes(newVal)) {

        this.career = null;

        this.employment = 2;

      } else {

        this.employment = 1;
      }
    },

    career(newVal){

      //console.log('onCareerChange', newVal);

      if (newVal === false) {

        this.career = null;

        return;
      }

      if (newVal !== 4)
        this.official = null;
      
      if (newVal !== 5)
        this.employee = null;
      
      if (newVal !== 6)
        this.worker = null;

      if (newVal !== null)
        this.employment = 1;
    },

    official(newVal){

      //console.log('onOfficialChange', newVal);

      if (newVal === false)
        this.official = null;
    },

    employee(newVal){

      //console.log('onEmployeeChange', newVal);

      if (newVal === false)
        this.employee = null;
    },

    worker(newVal){

      //console.log('onWorkerChange', newVal);

      if (newVal === false)
        this.worker = null;
    },
  },

  methods: {

    submit(){

      if (!this.checkDataIntegrity()) {

        this.$swal({
          text: 'Bitte wählen Sie für jede Frage auf der Seite eine Antwort aus.',
        });

        return false;
      }

      this.appWorking(this.scope);
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'submit-employment',
        data: {
          education: this.education,
          occupation: this.occupation,
          nonworking: this.nonworking,
          career: this.career,
          official: this.official,
          employee: this.employee,
          worker: this.worker,
          employment: this.employment,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {
      
          this.appSuccess('Ihre Angaben wurden erfolgreich gespeichert.', this.scope);

          this.$emit('cardSaved', this.$options.name);
        }
      )
      .fail(
        resp => {
      
          this.appError('Beim Speichern ist ein Fehler aufgetreten.', null, this.scope);
        }
      );

    },

    checkDataIntegrity(){

      if (this.education === null)
        return false;

      if (this.occupationVisible && !this.occupation)
        return false;

      if (this.nonworkingVisible && !this.nonworking)
        return false;

      if (this.careerVisible && !this.career)
        return false;

      if (this.employeeVisible && !this.employee)
        return false;

      if (this.officialVisible && !this.official)
        return false;

      if (this.workerVisible && !this.worker)
        return false;

      if (!this.employment)
        return false;

      return true;
    },
  },
}
</script>


<style scoped>
.qColl .qCont > *:not(.button) {
  padding: 0;
}
.qColl .qCont > div > * {
  padding: 12px;
}
</style>