<template>
  <QuestionWrapper
    :card="card"
  >

    <h3>
      Wie hoch ist das monatliche <u>Netto</u>einkommen Ihres Haushaltes insgesamt?
    </h3>

    <div>
      Gemeint ist damit die Summe, die sich ergibt aus Lohn, Gehalt, Einkommen aus selbständiger Tätigkeit, Rente oder Pension, jeweils <b>nach</b> Abzug der Steuern und Sozialversicherungsbeiträge. Rechnen Sie bitte auch die Einkünfte aus öffentlichen Beihilfen, Einkommen aus Vermietung, Verpachtung, Wohngeld, Kindergeld und sonstige Einkünfte hinzu.
    </div>

    <div>
      <AppRadio
        v-for="(c, i) in cats"
        :key="'category-' + i"
        v-model="response"
        :value="c.val"
        :class="i === cats.length - 1 ? 'dk' : ''"
      >
        {{ c.label }}
      </AppRadio>
    </div>

    <AppButton
      @click="submit"
      cls="material"
    >
      Speichern
    </AppButton>

  </QuestionWrapper>
</template>


<script>
import QuestionWrapper from '../QuestionWrapper'

export default {

  name: 'Income',

  components: {
    QuestionWrapper,
  },

  data(){
    return {
      response: this.details.household_income,
      cats: [
        {val: 1, label: 'unter 500 EUR'},
        {val: 2, label: '500 bis unter 1.000 EUR'},
        {val: 3, label: '1.000 bis unter 1.500 EUR'},
        {val: 4, label: '1.500 bis unter 2.000 EUR'},
        {val: 5, label: '2.000 bis unter 2.500 EUR'},
        {val: 6, label: '2.500 bis unter 3.000 EUR'},
        {val: 7, label: '3.000 bis unter 3.500 EUR'},
        {val: 8, label: '3.500 bis unter 4.000 EUR'},
        {val: 9, label: '4.000 bis unter 4.500 EUR'},
        {val: 10, label: '4.500 EUR oder mehr'},
        {val: 11, label: 'keine Angabe'},
      ],
    }
  },

  props: {
    details: Object,
    card: Object,
  },

  computed: {
    scope(){
      return 'save-' + this.$options.name;
    }
  },

  methods: {

    submit(){

      if (!this.response) {

        this.$swal({
          text: 'Bitte wählen Sie eine Antwortkategorie aus.',
        });

        return false;
      }

      this.appWorking(this.scope);
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'submit-income',
        data: {
          response: this.response,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {
      
          this.appSuccess('Ihre Antwort wurde erfolgreich gespeichert.', this.scope);

          this.$emit('cardSaved', this.$options.name);
        }
      )
      .fail(
        resp => {
      
          this.appError('Beim Speichern Ihrer Antwort ist ein Fehler aufgetreten.', null, this.scope);
        }
      );
    },
  },
}
</script>
