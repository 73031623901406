<template>
  <div
    id="header"
    :class="loggedIn ? 'loggedIn' : ''"
  >

    <HorizontalContainer>
      <AppLink
        @click="redirectToPortal"
      >
        <img src="@/assets/logo.png" />
      </AppLink>

      <Navigation
        v-if="loggedIn"
      />

      <UserBox
        v-if="loggedIn"
      />

      <FunctionalityAfterLogin
        v-if="loggedIn"
      />

    </HorizontalContainer>

  </div>
</template>


<script>
import navigationMixin from '@/components/mixins/navigationStore'

import HorizontalContainer from '@/components/wrapper/HorizontalContainer'
import Navigation from './header/TheNavigation'
import UserBox from './header/TheUserBox'
import FunctionalityAfterLogin from './header/TheFunctionalityAfterLogin'

export default {

  name: 'Header',

  components: {
    HorizontalContainer,
    Navigation,
    UserBox,
    FunctionalityAfterLogin,
  },

  mixins: [navigationMixin],

  methods: {

    redirectToPortal(){

      this.clearAppStatus();

      if (this.$route.name !== 'portal')
        this.$router.push({name: 'portal'});

      this.setSection('surveys');
    },
  },
}
</script>


<style scoped>
#header {
  position: relative;
  width: 100%;
  background: #efefee;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  z-index: 900;
}
#header .hContainer {
  padding: 0 6px;
}
#header img {
  margin: 15px 6px 9px 6px;
  max-height: 32px;
  max-width: calc(100% - 18px);
}
@media only screen and (max-width: 640px) {
  #header.loggedIn img {
    margin-left: 42px;
    max-width: calc(100% - 54px);
  }
  #header #userBox {
    display: none;
  }
}

</style>