<template>
  <div class="hContainer">
    <slot></slot>
  </div>
</template>


<script>

export default {
  name: 'HorizontalContainer',
}
</script>


<style scoped>
.hContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
</style>