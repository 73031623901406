<template>
  <div
    v-if="charts.length"
  >

    <h3>
      Aktuelle forsa-Ergebnisse
    </h3>

    <Swiper
      ref="mySwiper"
      class="chartContainer"
      :options="swiperOptions"
      @slideChange="getCurrentSlideIndex"
    >
      <SwiperSlide
        v-for="(c, i) in charts"
        :key="'chartContainer-' + i"
      >
        <Chart
          :key="'chart-' + i"
          :config="c"
        />
      </SwiperSlide>

      <div
        class="swiper-pagination" 
        slot="pagination"
      ></div>
      
      <div 
        v-if="charts.length > 1 && slideIndex > 1"
        class="swiper-button-prev" 
        slot="button-prev"
        @click="$refs.mySwiper.$swiper.slidePrev()"
      >
        <i class="fas fa-chevron-left fa-3x"></i>
      </div>
      
      <div 
        v-if="charts.length > 1 && slideIndex < charts.length"
        class="swiper-button-next" 
        slot="button-next"
        @click="$refs.mySwiper.$swiper.slideNext()"
      >
        <i class="fas fa-chevron-right fa-3x"></i>
      </div>
    </Swiper>

  </div>
</template>


<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';

import Chart from './survey/Chart';

export default {

  name: 'Charts',

  components: {
    Swiper,
    SwiperSlide,
    Chart,
  },

  computed: {
    swiper(){
      return this.$refs.mySwiper.$swiper;
    },
  },
  
  directives: {
    swiper: directive,
  },
  
  data(){
    return {
      charts: [],
      swiperOptions: {
        speed: 400,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      slideIndex: 1,
    }
  },

  props: {
    page: {
      type: String,
      default: 'portal',
    },
  },

  mounted(){

    if (this.page === 'portal')
      this.getCharts();

    if (this.page === 'login')
      this.getLoginCharts();
  },

  methods: {

    getCharts(){

      this.appWorking('get-charts');
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'get-charts',
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.clearAppStatus('get-charts');

          this.charts = readJson(resp);
        }
      )
      .fail(
        resp => {
      
          this.appError('Beim Seitenaufbau ist ein Fehler aufgetreten.');
        }
      );
    },

    getLoginCharts(){

      $.ajax({
        method: 'POST',
        url: this.urls.api + 'get-login-charts',
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.charts = readJson(resp);
        }
      )
    },

    getCurrentSlideIndex(swiper){

      this.slideIndex = swiper.activeIndex + 1;
    },
  },
}
</script>


<style scoped>
#surveys .chart {
  margin: 0 auto;
}
h3 {
  margin: 48px 0 24px 0;
}
</style>