<template>
  <QuestionWrapper
    :card="card"
  >

    <h3>
      Wie lautet Ihre E-Mail-Adresse?
    </h3>

    <AppInput
      v-model="email"
      placeholder="E-Mail-Adresse"
      class="noTitle"
    />

    <AppButton
      @click="submit"
      cls="material"
    >
      Speichern
    </AppButton>

  </QuestionWrapper>
</template>


<script>
import QuestionWrapper from '../QuestionWrapper'

export default {

  name: 'Contact',

  components: {
    QuestionWrapper,
  },

  data(){
    return {
      email: this.details.email,
    }
  },

  props: {
    details: Object,
    card: Object,
  },

  computed: {
    scope(){
      return 'save-' + this.$options.name;
    }
  },

  methods: {

    submit(){

      if (!this.email.length) {

        this.$swal({
          text: 'Bitte geben Sie an, an welche E-Mail-Adresse wir Einladungen zu Umfragen in forsa.omninet verschicken sollen.',
        });

        return false;
      }

      if (!this.validateEmail(this.email)) {

        this.$swal({
          text: 'Die eingegebene E-Mail-Adresse ist ungültig. Bitte überprüfen Sie die Eingabe und versuchen Sie es erneut.',
        });

        return false;
      }

      this.appWorking(this.scope);
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'submit-email',
        data: {
          email: this.email,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        (resp, status, xhr) => {

          if (xhr.status === 201) {

            this.appSuccess(
              'Ihre E-Mail-Adresse wurde erfolgreich gespeichert.', 
              this.scope
            );

          } else {

            this.appSuccess(
              'Ihre E-Mail-Adresse wurde erfolgreich gespeichert. Sie erhalten in Kürze eine E-Mail mit einem Aktivierungslink zur Bestätigung. Sobald Sie diesen Aktivierungslink geklickt haben, schicken wir Ihnen fortan die Einladungs-E-Mails zu neuen Umfragen an Ihre neue E-Mail-Adresse.', 
              this.scope
            );
          }

          this.$emit(
            'cardSaved', 
            this.$options.name
          );
        }
      )
      .fail(
        resp => {
      
          this.appError(
            'Beim Speichern der E-Mail-Adresse ist ein Fehler aufgetreten.', 
            null, 
            this.scope
          );
        }
      );
    },

    validateEmail(email){

      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(
        String(email).toLowerCase()
      );
    },
  },
}
</script>
