<template>
  <div
    :class="'qColl ' + card.card_name + ' ' + (needsAnswer ? '' : 'noDate')"
  >

    <div
      v-if="needsAnswer"
      class="header"
    >
      {{ headerText }}
    </div>

    <StatusMessage
      :scope="scope"
      v-if="scope in scopedStatus && scopedStatus[scope] > 1"
    />

    <div class="qCont">
      <slot></slot>
    </div>

  </div>
</template>


<script>
export default {

  name: 'ProfileQuestion',

  props: {
    card: Object,
  },

  computed: {

    scope(){
      return 'save-' + this.card.vue_component_name;
    },

    needsAnswer(){
      return this.card.needs_refresh || !this.card.has_value ? true : false;
    },

    headerText(){

      if (!this.card.has_value)
        return 'Bitte vervollständigen Sie Ihre Angaben';

      return 'Sind diese Angaben noch aktuell?';
    },
  },
}
</script>


<style>
.qColl {
  position: relative;
}
.qColl .qCont > * {
  padding: 12px;
}
.qColl > .header {
  font-family: Georgia, 'Times New Roman', Times, serif;
  padding: 48px 6px 12px 6px;
  margin-top: -1px;
  margin-bottom: 12px;
  font-size: 150%;
  text-align: center;
  background: #d8d8d5;
}
.qColl .qCont > .button {
  text-align: center;
}
.qColl > .title,
.qColl > .date {
  font-family: 'Nunito Sans', Helvetica, Arial, sans-serif;
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 12px;
  color: #636355;
}
.qColl.noDate > .date {
  display: none;
}
.qColl > .title {
  left: 6px;
  right: auto;
}
.qColl > .messageContainer {
  margin: 48px 12px 24px 12px;
  width: auto;
}
.qColl .fade-enter-active/*,
.qColl .fade-leave-active*/ {
  transition: opacity .2s ease-out;
}
.qColl .fade-enter/*,
.qColl .fade-leave-to*/ {
  opacity: 0;
}
</style>