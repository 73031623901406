import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/fonts/nunito-sans-v2-latin.css'
import '@fortawesome/fontawesome-free/css/all.min.css'

// import and register jQuery
import $ from 'jquery'
window.$ = $
window.jQuery = $

// import and register sweet-alert
import VueSwal from 'vue-swal'
Vue.use(VueSwal)

// import and register moment.js
import moment from 'moment'
Vue.prototype.moment = moment

// import and register forsa-webapp-control-collection
import {
  AppAutocompleteInput,
  AppButton,
  AppCheckbox,
  AppInput, 
  AppLink, 
  AppPasswordInput,
  AppRadio,
  AppSelect,
  AppTextToggle,
  AppTextarea
} from '@forsa/vue-webapp-controls'

Vue.component('AppAutocompleteInput', AppAutocompleteInput)
Vue.component('AppButton', AppButton)
Vue.component('AppCheckbox', AppCheckbox)
Vue.component('AppInput', AppInput)
Vue.component('AppLink', AppLink)
Vue.component('AppPasswordInput', AppPasswordInput)
Vue.component('AppRadio', AppRadio)
Vue.component('AppSelect', AppSelect)
Vue.component('AppTextToggle', AppTextToggle)
Vue.component('AppTextarea', AppTextarea)

// import and register forsa-webapp-wrapper-collection
import {
  InfoButton,
  Popup,
  AppTabs,
  AppTable
} from '@forsa/vue-webapp-wrappers'

Vue.component('InfoButton', InfoButton)
Vue.component('Popup', Popup)
Vue.component('AppTabs', AppTabs)
Vue.component('AppTable', AppTable)

// import and register forsa-app-status-library
import {
  appStatusMixin,
  StatusIcon,
  StatusMessage,
  FullscreenSpinner
} from '@forsa/vue-webapp-status'

Vue.mixin(appStatusMixin)
Vue.component('StatusIcon', StatusIcon)
Vue.component('StatusMessage', StatusMessage)
Vue.component('FullscreenSpinner', FullscreenSpinner)

// define global json-parse-wrapper-function
window.readJson = json => {
  try {

    let parsed = JSON.parse(json);
    return parsed;

  } catch(e) {

    console.error('Error parsing JSON: \n\n', e, json);
    this.appError(null, json);
    return false;
  }
}

// register the global event-hub
Vue.prototype.$eventHub = new Vue()

// store server-address
let l = window.location;

let api = {
  key: 'api',
  value: (
            process.env.NODE_ENV === 'production' ? 
            '/' :
            l.protocol + '//' + l.hostname + '/omninet/'
         ) + 'api/',

}

store.dispatch('storeUrl', api)

import urlStoreMixin from '@/components/mixins/urlStore'
Vue.mixin(urlStoreMixin)

// instantiate Vue
new Vue({
  router,
  store,
  render: h => h(App)
})
.$mount('#app')
