export default {

  computed: {

    urls(){

      return this.$store.getters.urls
    },
  },

  methods: {

    storeUrl(key, value){

      if (key && value) {

        let arg = {
            key: key,
            value: value,
        }

        this.$store.dispatch('storeUrl', arg)
      }
    },
  },
}
  