export default {

  computed: {

    loggedIn(){

      return this.$store.state.navigationStore.loggedIn
    },

    section(){

      return this.$store.state.navigationStore.section
    },
  },

  methods: {

    setLogin(state){

      this.$store.dispatch('setLoginState', state)
    },

    setSection(section){

      this.$store.dispatch('setSection', section)
    },
  },
}
