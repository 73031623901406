<template>
  <div id="account">
    
    <h1>
      Punktekonto
    </h1>

    <StatusMessage
      v-if="'incentive-model' in scopedStatus && scopedStatus['incentive-model'] !== 1"
      scope="incentive-model"
    />

    <div class="grid">
      <div>
        aktueller Kontostand:
      </div>
      <div>
        <ICountUp
          :endVal="balance"
          class="large"
          :options="countUpOptions"
        />
        Punkte
      </div>
      <div>
        nächste Auszahlung:
      </div>
      <div>
        {{ date }}
        <InfoButton>
					Bitte beachten Sie, dass die Auszahlung immer nur in 500-Punkte-Schritten bzw. für die Auszahlungsvariante <b>Aktion Mensch</b> in 750-Punkte-Schritten erfolgen kann. Sollten Sie zum Zeitpunkt der nächsten Auszahlung weniger Punkte gesammelt haben, bleiben Ihnen diese selbstverständlich bis zur darauf folgenden Auszahlung erhalten.
        </InfoButton>
      </div>
      <div>
        gewählte Auszahlungsvariante:
      </div>
      <div>
        <AppSelect
          v-model="model"
          nullCategory="--- bitte auswählen ---"
          :options="[{value: 3, label: 'Universal Einkaufs­gutschein von cadooz'},{value: 2, label: 'Lose für Aktion Mensch'},{value: 4, label: 'Spende an Unicef'},{value: 5, label: 'Spende an SOS-Kinderdorf'}]"
        />

        <AppButton
          cls="material"
          v-if="model && origModel !== model"
          @click="submitIncentiveModel"
        >
          Auszahlungsvariante speichern
        </AppButton>
      </div>
    </div>

    <h3>
      Auszahlungen &amp; Gutscheine
    </h3>

    <PaymentHistory />

    <h3>
      Gutschriften &amp; Umsätze
    </h3>

    <AccountHistory />

  </div>
</template>


<script>
import ICountUp from 'vue-countup-v2';

import AccountHistory from './account/AccountHistory'
import PaymentHistory from './account/PaymentHistory'

export default {

  name: 'Account',

  components: {
    ICountUp,
    AccountHistory,
    PaymentHistory,
  },

  data(){
    return {
      date: '',
      model: '',
      origModel: '',
      countUpOptions: {
        duration: 1.2,
        useEasing: true,
      },
    }
  },

  computed: {

    balance(){

      return this.$store.getters.getBalance
    },
  },

  mounted(){

    this.getNextPaymentDate();

    this.getIncentiveModel();
  },

  methods: {

    getNextPaymentDate(){

      this.appWorking('nextPayment');
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'get-next-payment-date',
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {
      
          let date = readJson(resp);

          this.date = this.moment(date).format('DD.MM.YYYY');

          this.clearAppStatus('nextPayment');
        }
      )
      .fail(
        resp => {
          this.appError('Beim Seitenaufbau ist ein Fehler aufgetreten.');
        }
      );
    },

    getIncentiveModel(){

      this.appWorking('incentiveModel');
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'get-incentive-model',
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {
      
          this.model = readJson(resp);

          this.origModel = this.model;

          this.clearAppStatus('incentiveModel');
        }
      )
      .fail(
        resp => {
          this.appError('Beim Seitenaufbau ist ein Fehler aufgetreten.');
        }
      );
    },

    submitIncentiveModel(){

      this.appWorking('incentive-model');
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'set-incentive-model',
        data: {
          model: this.model,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {
      
          this.appSuccess('Die Auszahlungsvariante wurde erfolgreich geändert.', 'incentive-model');

          this.origModel = this.model;
        }
      )
      .fail(
        resp => {
          this.appError('Beim Speichern der Auszahlungsvariante ist ein Fehler aufgetreten.', null, 'incentive-model');
        }
      );
    },

  },
}
</script>


<style scoped>
#account > * {
  margin: 0 0 24px 0;
  max-width: 800px;
}
#account > h1 {
  margin-bottom: 48px;
}
#account > h3 {
  margin-top: 48px;
}
#account .grid {
  display: grid;
  grid-template-columns: minmax(40%, 96px) auto;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  align-items: baseline;
  line-height: 100%;
  margin-top: 48px;
}
#account .grid > div:not(:last-of-type) {
    font-size: 110%;
}
#account .grid > div:nth-of-type(2n+1) {
  text-align: right;
}
#account .grid .button {
  margin-top: 12px;
  text-align: center;
}
#account .large {
  font-size: 400%;
  font-weight: bold;
}
#account .select {
  font-size: 90%;
}
@media only screen and (max-width: 640px) {
  #account .grid {
    display: block;
    line-height: 1;
    margin-top: 0;
  }
  #account .grid > div:nth-of-type(2n+1) {
    margin-top: 24px;
  }
  #account .grid > div:not(:last-of-type) {
    text-align: center;
  }
}
</style>