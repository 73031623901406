<template>
  <div id="portalContent">

    <StatusMessage
      v-if="appStatus > 1"
    />
    
    <!-- <Overview
      v-if="section === 'overview'"
    /> -->

    <AdditionalPortalContent />

    <Surveys
      v-if="section === 'surveys'"
    />

    <Account
      v-if="section === 'account'"
    />

    <Profile
      v-if="section === 'profile'"
    />

    <Help
      v-if="section === 'help'"
    />

    <Quit
      v-if="section === 'quit'"
    />

  </div>
</template>


<script>
import navigationMixin from '@/components/mixins/navigationStore'

import AdditionalPortalContent from './portal/TheAdditionalContent'
import Overview from './portal/TheOverview'
import Surveys from './portal/TheSurveys'
import Account from './portal/TheAccount'
import Profile from './portal/TheProfile'
import Help from './portal/TheHelp'
import Quit from './portal/help/Quit'

export default {

  name: 'PortalContent',

  components: {
    AdditionalPortalContent,
    Overview,
    Surveys,
    Account,
    Profile,
    Help,
    Quit,
  },

  mixins: [navigationMixin],
}
</script>


<style scoped>
#portalContent > * {
  margin-bottom: 24px;
  hyphens: auto;
  -webkit-hyphenate-limit-lines: 3;
}
#portalContent > .messageContainer,
#portalContent > h1 {
  margin-bottom: 48px;
}
</style>