<template>
  <QuestionWrapper
    :card="card"
  >

    <h3>
      Wo sind Sie zuhause?
    </h3>

    <div>
      Viele unserer Ergebnisse werden nach Faktoren wie der Ortsgröße, dem 
      Bundesland oder besonderen regionalen Faktoren ausgewertet. Einige Studien 
      werden auch nur in einigen bestimmten Regionen oder Bundesländern 
      durchgeführt, je nachdem, um welches Thema es geht. Daher ist es für uns 
      sehr wichtig, zu wissen, wo Sie <b>hauptsächlich</b> wohnen.
      <br /><br />
      Bitte tippen Sie hierzu entweder Ihre Postleitzahl oder den Namen Ihres 
      Wohnortes in das Feld ein und wählen Sie den zutreffenden Eintrag aus der 
      Liste aus. Ist Ihr Wohnort nicht verfügbar, 
      <a href="#" @click.prevent="goToHelp">
        <span>wenden Sie sich bitte an unser Support-Team</span>
      </a>.
    </div>

    <div class="grid">
      <AppAutocompleteInput
        v-model="input"
        :url="url"
        displayKey="display"
        :asObject="true"
        @input="onResidenceChange"
      >
        Postleitzahl/Ort:
      </AppAutocompleteInput>
      <div>
        <i
          :class="'fas fa-fw ' + (valid ? 'fa-check-circle succ' : 'fa-exclamation-circle err')"
          style="font-size: 125%;"
        >
        </i>
      </div>
    </div>

    <AppButton
      @click="submit"
      cls="material"
    >
      Speichern
    </AppButton>

  </QuestionWrapper>
</template>


<script>
import QuestionWrapper from '../QuestionWrapper'

export default {

  name: 'Residence',

  components: {
    QuestionWrapper,
  },

  data(){
    return {
      response: this.getResidence(),
      input: this.getInput(),
    }
  },

  props: {
    details: Object,
    card: Object,
  },

  computed: {

    scope(){

      return 'save-' + this.$options.name;
    },

    url(){

      return this.urls.api + 'get-residence-suggestions';
    },

    valid(){

      return this.response && typeof this.response === 'object' && 'gkz' in this.response;
    },
  },

  methods: {

    getResidence(){

      return {
        gkz: this.details.gkz,
        zip_code: this.details.zip_code,
      }
    },

    getInput(){

      return this.details.residence;
    },

    onResidenceChange(){

      this.response = Object.assign({}, this.input)
    },

    submit(){

      if (!this.valid) {

        this.$swal({
          text: 'Bitte wählen Sie einen Eintrag aus der Liste aus, nachdem Sie Ihre Postleitzahl oder Ihren Ort eingegeben haben. Nachdem Sie einen Eintrag ausgewählt haben, sollten Sie diesen nicht mehr bearbeiten. \n\nZur Orientierung sehen Sie rechts neben dem Eingabefeld ein grünes oder rotes Symbol, das Ihnen anzeigt, ob Ihre Eingabe gültig ist.\n\nFalls Sie Ihre Postleitzahl bzw. Ihren Ort nicht finden, wenden Sie sich bitte an unser Support-Team.',
        });

        return false;
      }

      this.appWorking(this.scope);
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'submit-residence',
        data: {
          postCode: this.response.post_code,
          gkz: this.response.gkz,
          district: this.response.district,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {
      
          this.appSuccess(
            'Ihre Angaben wurden erfolgreich gespeichert.', 
            this.scope
          );

          this.$emit(
            'cardSaved', 
            this.$options.name
          );
        }
      )
      .fail(
        resp => {
      
          this.appError(
            'Beim Speichern ist ein Fehler aufgetreten.', 
            null, 
            this.scope
          );
        }
      );

    },

    goToHelp(){

      this.$router.push({name: 'portal-help-contact'});
    },
  },
}
</script>


<style scoped>
.qColl .grid {
  display: grid;
  grid-template-columns: auto 25px;
  grid-column-gap: 3px;
  align-items: center;
}
.qColl .input {
  display: grid;
  grid-template-columns: minmax(20%, 144px) auto;
  grid-column-gap: 6px;
}
.qColl .input >>> .suggestions {
  max-height: 144px;
  overflow-y: auto;
}
.qColl .input >>> input {
  padding-right: 27px;
}
.qColl .succ {
  color: #8bc34a;
}
.qColl .err {
  color: #cc0000;
}
</style>