<template>
  <QuestionWrapper
    :card="card"
  >

    <h3>
      Wie sollen wir Sie ansprechen? 
    </h3>

    <div>
      <AppRadio
        v-model="form"
        :value="1"
      >
        ich bevorzuge eine persönliche Ansprache mit meinem Namen
      </AppRadio>

      <AppRadio
        v-model="form"
        :value="2"
      >
        ich möchte meinen Namen nicht angeben und bleibe lieber anonym
      </AppRadio>
    </div>

    <transition name="fade">
      <div v-if="form === 1">
        <AppInput
          v-model="firstName"
          title="Vorname"
        >
          Vorname
        </AppInput>

        <AppInput
          v-model="lastName"
          title="Nachname"
        >
          Nachname
        </AppInput>      
      </div>
    </transition>

    <AppButton
      @click="submit"
      cls="material"
    >
      Speichern
    </AppButton>

  </QuestionWrapper>
</template>


<script>
import QuestionWrapper from '../QuestionWrapper'

export default {

  name: 'Salutation',

  components: {
    QuestionWrapper,
  },

  data(){
    return {
      form: this.details.form,
      firstName: this.details.first_name,
      lastName: this.details.last_name,
    }
  },

  props: {
    details: Object,
    card: Object,
  },

  computed: {
    scope(){
      return 'save-' + this.$options.name;
    }
  },

  methods: {

    submit(){

      this.appWorking(this.scope);
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'submit-salutation-details',
        data: {
          form: this.form,
          firstName: this.firstName,
          lastName: this.lastName,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {
      
          this.appSuccess(
            'Ihre Einstellungen wurden erfolgreich gespeichert.', 
            this.scope
          );

          this.$emit(
            'cardSaved', 
            this.$options.name
          );
        }
      )
      .fail(
        resp => {
      
          this.appError(
            'Beim Speichern ist ein Fehler aufgetreten.', 
            null, 
            this.scope
          );
        }
      );
    },
  },
}
</script>


<style scoped>
.qColl .input {
  display: grid;
  grid-template-columns: minmax(20%, 144px) auto;
  grid-column-gap: 6px;
  margin-bottom: 6px;
}
</style>