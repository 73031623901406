<template>
  <div id="additional">

    <div
      v-for="(c, i) in content"
      :key="'additionalContent-' + i"
      :class="'contentContainer-' + c.type"
    >
      <div
        v-if="c.type === 'card'"
        class="card"
        v-html="c.html"
      >
      </div>

      <Popup
        v-if="c.type === 'popup' && showPopup[i]"
        @closePopup="$set(showPopup, i, false)"
        width="640px"
        height="480px"
      >
        <div
          v-html="c.html"
          class="pop"
        >
        </div>
      </Popup>
    </div>

  </div>
</template>


<script>
export default {

  name: 'AdditionalPortalContent',

  data(){
    return {
      content: [],
      showPopup: [],
    }
  },

  mounted(){

    this.getAdditionalContent();
  },

  methods: {

    getAdditionalContent(){

      this.appWorking('get-content');
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'get-additional-portal-content',
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.clearAppStatus('get-content');
      
          this.content = readJson(resp);

          this.content.forEach(
            c => {

              if (c.type === 'popup')
                this.showPopup.push(true)
              else
                this.showPopup.push(false)
            }
          )
        }
      )
      .fail(
        resp => {
      
          this.appError(
            'Ein Fehler ist aufgetreten.', 
            null, 
            'get-content'
          );
        }
      );
    },

  },
}
</script>


<style>
#additional {
  margin-bottom: 0;
}
#additional > div.contentContainer-card:last-of-type {
  margin-bottom: 48px;
}
#additional .card {
  margin-bottom: 24px;
  max-width: 800px;
}
#additional .card > div {
  margin-bottom: 12px;
}
#additional .pop > div {
  margin-bottom: 12px;
}
</style>