<template>
  <div id="quit">
        
    <h1>
      Teilnahme kündigen
    </h1>
    
    <div>
      Wir bedauern es sehr, dass Sie sich aus forsa.omninet abmelden wollen. Ihre Antworten sind sehr wichtig, um ein möglichst breites, repräsentatives Meinungsbild zu erhalten.
    </div>

    <AppCheckbox
      v-model="confirm"
    >
      Ja, ich möchte mich wirklich aus forsa.omninet abmelden
    </AppCheckbox>

    <h3
      v-if="confirm"
    >
      Dürfen wir Sie fragen, was wir in Zukunft vielleicht besser machen können?
    </h3>

    <AppTextarea
      v-if="confirm"
      v-model="improvements"
      class="noTitle"
    />

    <AppButton
      v-if="confirm"
      cls="material"
      @click="submit"
    >
      Jetzt unwiderruflich aus forsa.omninet abmelden
    </AppButton>

  </div>
</template>


<script>

export default {

  name: 'Quit',

  data(){
    return {
      confirm: false,
      improvements: '',
    }
  },

  methods: {

    submit(){

      this.appWorking();
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'quit-membership',
        data: {
          improvements: this.improvements,
        },
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {
      
          this.appSuccess(
            'Ihre Teilnahme an forsa.omninet wurde erfolgreich widerrufen. Ihre Daten wurden gelöscht. Sie werden nun aus dem Teilnehmerbereich abgemeldet.'
          );

          window.setTimeout(
            () => {

              this.$router.push({name: 'logout'});
            },
            3000
          );
        }
      )
      .fail(
        resp => {
      
          this.appError(
            'Bei der Abmeldung aus forsa.omninet ist ein Fehler aufgetreten. Bitte kontaktieren Sie unser Support-Team.'
          );
        }
      );
    },
  },
}
</script>


<style scoped>
#quit > * {
  margin: 0 0 24px 0;
  hyphens: auto;
  max-width: 800px;
}
#quit > h1 {
  margin-bottom: 48px;
}
#quit > h3 {
  margin-top: 48px;
}
</style>