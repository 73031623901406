<template>
  <div class="menuLinks">
    <div
      class="closeContainer"
    >
      <AppLink
        class="toggleMenu"
        @click="$emit('hideMenu')"
        title="Menü schließen"
      >
        <img src="@/assets/logo.png" />
        ✕
      </AppLink>
    </div>

    <div class="linkContainer">
      <router-link
        to="/portal/surveys"
        @click.native="$emit('hideMenu')"
        :class="section === 'surveys' ? 'router-link-active' : ''"
      >
        <span tabindex="-1">
          <i class="fas fa-clipboard-list fa-fw"></i>
          Umfragen
        </span>
        <div
          v-if="openSurveyCount + openProfileCount > 0"
          class="count"
        >
          {{ openSurveyCount + openProfileCount }}
        </div>
      </router-link>
    </div>

    <div class="linkContainer">
      <router-link
        to="/portal/account"
        @click.native="$emit('hideMenu')"
      >
        <span tabindex="-1">
          <i class="fas fa-piggy-bank fa-fw"></i>
          Punkte&shy;konto
        </span>
      </router-link>
    </div>

    <div class="linkContainer">
      <router-link
        to="/portal/profile"
        @click.native="$emit('hideMenu')"
      >
        <span tabindex="-1">
          <i class="fas fa-portrait fa-fw"></i>
          Profil

        </span>
      </router-link>
    </div>

    <div></div>

    <div></div>

    <div></div>

    <div class="linkContainer">
      <router-link
        to="/portal/help"
        @click.native="$emit('hideMenu')"
      >
        <span tabindex="-1">
          <i class="fas fa-question-circle fa-fw"></i>
          Hilfe
        </span>
      </router-link>
    </div>

    <div class="linkContainer">
      <router-link to="/logout">
        <span tabindex="-1">
          <i class="fas fa-power-off fa-fw"></i>
          Abmelden
        </span>
      </router-link>
    </div>
  </div>
</template>


<script>
import navigationMixin from '@/components/mixins/navigationStore'

export default {

  name: 'NavigationLinks',

  mixins: [navigationMixin],

  computed: {

    openSurveyCount(){

      return this.$store.getters.getCurrentSurveyCount;
    },

    openProfileCount(){

      return this.$store.getters.getCurrentProfileCount;
    },
  },

}
</script>


<style>
.closeContainer .link,
.closeContainer a {
  display: block !important;
  text-align: right;
}
.closeContainer .link a {
  padding-right: 12px;
  font-family: Arial,sans-serif;
  font-size: 18px;
  font-weight: bold;
}
@keyframes showNavigation {
  1% {
    left: -92%
  }
  10% {
    left: -60%
  }
  25% {
    left: -30%
  }
  40% {
    left: -14%
  }
  60% {
    left: -5%
  }
  80% {
    left: -1%
  }
  to {
    left: 0
  }
}
.menuLinks {
  box-shadow: 0 9px 18px rgba(0,0,0,.19), 0 6px 6px rgba(0,0,0,.23);
  position: absolute;
  width: 100%;
  top: 0;
  max-height: 100vh;
  overflow-y: auto;
  left: -100%;
  animation: showNavigation 0.3s forwards;
}
.linkContainer {
  position: relative;
}
.linkContainer i {
  font-size: 21px;
  margin-right: 9px;
  vertical-align: bottom;
}
.closeContainer,
.linkContainer {
  background: #fff;
}
.closeContainer img {
  position: absolute;
  left: 0;
  top: 0;
  margin: 15px 24px 9px 48px;
  max-height: 32px;
  max-width: calc(100% - 84px);
  outline: none !important;
}
.linkContainer a {
  display: block;
  padding: 12px;
  color: #000;
  text-decoration: none;
  user-select: none;
}
.linkContainer a:hover {
  color: #e63223;
}
.linkContainer a.router-link-active {
  color: #fff;
  background: #e63223;
}
.linkContainer .count {
	position: absolute;
	top: 0;
	left: 100%;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	line-height: 16px;
	font-size: 12px;
	background: #fff;
	text-align: center;
	font-family: 'Nunito Sans', Helvetica, Arial, sans-serif;
	background: #e63223;
	color: #fff;
	box-shadow: 0 0 1px #e63223,0 0 1.5px #bbb;
	text-shadow: 0 0 1px #fff;
	transition: background 0.1s ease-out;
}
@media only screen and (min-width: 640px) {
  .closeContainer {
    display: none;
  }
  /*.subMenu,*/
  .menuLinks {
    display: flex;
    justify-content : space-between;
    align-items: end;
    box-shadow: none;
    position: static;
    animation: none;
    padding-top: 12px;
  }
  .linkContainer .far,
  .linkContainer .fas {
    display: none;
  }
  .linkContainer {
    background: transparent !important;
  }
  .menuLinks .linkContainer a {
    color: #e63223 !important;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin: 0 6px;
    font-size: 18px;
    padding: 12px 0 18px 0;
    background: none !important;
    position: relative;
    text-align: center;
  }
  /*.menuLinks .linkContainer a.submenu-expanded::before,*/
  /*.menuLinks .linkContainer a.router-link-active::before,*/
  .menuLinks .linkContainer a > span::after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-color: #e63223;
    margin-top: 6px;
    transform-origin: top;
    transform: scaleY(1);
    transition: transform 0.2s ease;
  }
  /*.menuLinks .linkContainer a.submenu-expanded::before,
  .menuLinks .linkContainer a.router-link-active::before {
    top: 0;
    margin-top: 3px;
    position: absolute;
  }*/
  .menuLinks .linkContainer a.router-link-active > span::after,
  .menuLinks .linkContainer a:focus > span::after,
  .menuLinks .linkContainer a:hover > span::after {
    transform: scaleY(2);
  }
  /*.subMenu .linkContainer a {
    font-family: Georgia, 'Times New Roman', Times, serif;
    padding: 12px 6px 18px 6px;
    margin: 0 6px;
    font-size: 16px;
  }
  .subMenu .linkContainer a.router-link-active {
    background: none;
    text-decoration: underline;
    color: #e63223;
  }*/
}
@media only screen and (max-width: 640px) {
  /*.subMenu .linkContainer a {
    padding-left: 24px;
    position: relative;
  }*/
  div.linkContainer:not(:last-of-type)::after/*,
  .subMenu::after*/ {
    content: '';
    width: calc(100% - 24px);
    border-bottom: 1px solid #efefee;
    bottom: 0;
    margin-left: 12px;
    display: block;
  }
  .linkContainer a.router-link-active:hover,
  .linkContainer a.router-link-active:focus span {
    color: #f9ccc8;
  }
  .linkContainer .count {
    left: 32px;
    top: 3px;
  }
  .linkContainer a.router-link-active .count {
    background: #fff;
    color: #e63223;
    box-shadow: 0 0 2px #fff;
    text-shadow: none;
  }
  .linkContainer a.router-link-active:hover .count {
    background: #f9ccc8;
    box-shadow: 0 0 2px #f9ccc8;
  }
}
</style>