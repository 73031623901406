<template>
  <div
    v-if="cards && details"
    id="profile"
  >
    
    <h1>
      Profil
    </h1>

    <Address
      :cards="cards"
      :details="details"
      @refreshDetails="getMemberDetails"
    />

    <Politics
      :cards="cards"
      :details="details"
      @refreshDetails="getMemberDetails"
    />

    <Education
      :cards="cards"
      :details="details"
      @refreshDetails="getMemberDetails"
    />

    <Living
      :cards="cards"
      :details="details"
      @refreshDetails="getMemberDetails"
    />

  </div>
</template>


<script>
import Address from './profile/Address'
import Politics from './profile/Politics'
import Education from './profile/Education'
import Living from './profile/Living'

export default {

  name: 'Profile',

  components: {
    Address,
    Politics,
    Education,
    Living,
  },

  data(){
    return {
      cards: null,
      details: null,
      ajaxReady: 0,
    }
  },

  watch: {

    ajaxReady(newValue){
      
      if (newValue === 2)
        this.amendCardDetails()
    },
  },

  mounted(){
    
    this.getCardValidities();

    this.getMemberDetails();
  },

  methods: {

    getCardValidities(){
      
      this.appWorking('get-cards');
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'get-profile-cards',
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.clearAppStatus('get-cards');
      
          this.cards = readJson(resp);

          this.ajaxReady++;
        }
      )
      .fail(
        resp => {
      
          this.appError('Beim Seitenaufbau ist ein Fehler aufgetreten.');
        }
      );
    },

    getMemberDetails(){

      this.appWorking('get-details');
      
      $.ajax({
        method: 'POST',
        url: this.urls.api + 'get-member-details',
        xhrFields: {
          withCredentials: true
        }
      })
      .done(
        resp => {

          this.clearAppStatus('get-details');
      
          this.details = readJson(resp);

          this.ajaxReady++;
        }
      )
      .fail(
        resp => {
      
          this.appError('Beim Seitenaufbau ist ein Fehler aufgetreten.');
        }
      );
    },

    amendCardDetails(){

      let details = this.details;

      this.cards.forEach(
        c => {
          
          c.valid_to = c.validity ? this.moment().valueOf() - (c.validity * 24 * 60 * 60 * 1000) : 0;

          let variable = c.variable_name;

          c.has_value = variable in details && !!details[variable];

          c.needs_refresh = this.moment(details[variable]).valueOf() < c.valid_to;

          c.date = this.moment().format('DD.MM.YYYY');
        }
      );

      this.orderCards();
    },

    orderCards(){

      this.cards.sort(
        (a, b) => {

          // no value found -> order to top
          if (!b.has_value)
            return 1;

          // refresh needed -> check if a also needs refresh, if yes compare validities, else order to top
          if (b.needs_refresh) {

            if (a.needs_refresh)
              return b.valid_to > a.valid_to ? 1 : 0;
            else
              return 1;
          }

          // card has value and doesn't need refresh -> stay in this order
          return 0;
        }
      );
    },

  },
}
</script>


<style>
#profile > * {
  margin: 0 0 36px 0;
  max-width: 800px;
}
#profile > h1 {
  margin-bottom: 48px;
}
#profile .tabs > .content {
  padding: 12px 0 0 0;
}
#profile .tabs > .content > * {
  padding: 0;
}
#profile input,
#profile select {
  font-size: 90%;
}
#profile .dk {
  font-style: italic;
  color: #999;
  margin-top: 9px;
}
#profile .tabs .qColl .header {
  display: none;
}
@media only screen and (max-width: 480px) {
  #profile .tabs > .header .formControlHolder .labelContainer {
    padding: 3px 6px;
  }
  #profile .tabs > .header .formControlHolder:first-of-type .labelContainer {
    padding-left: 6px !important;
  }
}
</style>